import React from "react";
import SelectComponent from "react-select";

export default function Select({
  options = [],
  label = "",
  labelClass = "text-sm font-semibold text-lightBlack",
  errorMessage,
  ...props // make sure this pops here is for input only
}) {
  const customStyles = {
    control: (styles) => {
      //console.log("stules", styles);
      return {
        ...styles,
        backgroundColor: "white",
        borderColor: errorMessage ? "red" : "#dfdfdf",
        height: 51,
        borderRadius: 6,
        minHeight: 51,
        boxShadow: "none",
        "&:hover": {
          border: "1px solid #2563eb !important",
        },
        paddingLeft: 8,
      };
    },
    option: (provided) => {
      return {
        ...provided,
        padding: "8px 18px",
      };
    },
  };

  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={label} className={labelClass}>
          {label}
        </label>
      )}
      <SelectComponent
        className={label && "mt-2"}
        options={options}
        styles={customStyles}
        components={{
          IndicatorSeparator: () => null,
        }}
        {...props}
      />
      {errorMessage && <p className="input-error-message mt-2 -mb-1">* {errorMessage}</p>}
    </div>
  );
}

import moment from "moment";

export const csx = (classes) => classes.filter(Boolean).join(" "); 

export const urlSerializer = (endpoint, obj) => {
  let params = new URLSearchParams(obj);
  let keysToDelete = [];
  params.forEach((value, key) => {
    if (value === "" || value === "null") {
      keysToDelete.push(key);
    }
  });
  keysToDelete.forEach((key) => {
    params.delete(key);
  });
  return `${endpoint}?${params.toString()}`;
};


export const formatDate = (obj, dateFormat) => {
  const {
    start_month,
    start_day,
    start_year,
    end_month,
    end_day,
    end_year,
    is_start_date_approximately,
    is_end_date_approximately,
    is_present,
  } = obj;
  let date = "";

  if (is_start_date_approximately) {
    if (start_month && start_year)
      date = `${moment()
        .month(start_month - 1)
        .format(!dateFormat ? "MM/DD/YY" : "DD/MM/YY")} ${start_year} (Approximately)`;
    else if (start_year) date = start_year + " (Approximately)";
  } else {
    if (start_day && start_month && start_year) {
      if (
        Intl.DateTimeFormat().resolvedOptions().timeZone ===
          "America/New_York" ||
        Intl.DateTimeFormat().resolvedOptions().timeZone ===
          "America/Los_Angeles"
      ) {
        date = moment([start_year, start_month - 1, start_day]).format(
          !dateFormat ? "MM/DD/YY" : "DD/MM/YY"
        );
      } else {
        date = moment([start_year, start_month - 1, start_day]).format(
          !dateFormat ? "MM/DD/YY" : "DD/MM/YY"
        );
      }
    } else {
      if (start_month && start_year)
        date = `${moment()
          .month(start_month - 1)
          .format(!dateFormat ? "MM/DD/YY" : "DD/MM/YY")} ${start_year}`;
      else if (start_year) date = start_year;
    }
  }

  if (
    (start_day || start_month || start_year) &&
    (end_day || end_month || end_year || is_present)
  )
    date = date + " — ";

  if (is_present) {
    date = date + "Present";
  } else {
    if (is_end_date_approximately) {
      if (end_month && end_year)
        date =
          date +
          `${moment()
            .month(end_month - 1)
            .format(!dateFormat ? "MM/DD/YY" : "DD/MM/YY")} ${end_year} (Approximately)`;
      else if (end_year) date = end_year + " (Approximately)";
    } else {
      if (end_day && end_month && end_year) {
        if (
          Intl.DateTimeFormat().resolvedOptions().timeZone ===
            "America/New_York" ||
          Intl.DateTimeFormat().resolvedOptions().timeZone ===
            "America/Los_Angeles"
        ) {
          date =
            date +
            moment([end_year, end_month - 1, end_day]).format(!dateFormat ? "MM/DD/YY" : "DD/MM/YY");
        } else {
          date =
            date +
            moment([end_year, end_month - 1, end_day]).format(!dateFormat ? "MM/DD/YY" : "DD/MM/YY");
        }
      } else {
        if (end_month && end_year)
          date =
            date +
            `${moment()
              .month(end_month - 1)
              .format(!dateFormat ? "MM/DD/YY" : "DD/MM/YY")} ${end_year}`;
        else if (end_year) date = date + `${end_year}`;
      }
    }
  }

  return date;
};
import { csx } from "utils/utils";
import logo from "assets/images/logo-white.png";
import { useMemo } from "react";
import { Link } from "react-router-dom";

const noop = () => {};

const NotificationItemSystem = ({ notification, onPreviewClick = noop }) => {
  const messageClasses = csx([
    "text-mutedGray text-base",
    notification.message.length > 80 ? "truncate" : "break-words",
  ]);

  const title = useMemo(() => {
    if (notification.category !== "system") return notification.title;
    return notification.type === "push"
      ? "New Popout Success"
      : notification.type === "banner"
      ? "New Banner Success"
      : "New System Notification";
  }, [notification]);

  const message = useMemo(() => {
    const type = notification.type === "push" ? "popout" : "banner";
    return `You successfully created new ${type} titled "${notification.title}"`;
  }, [notification]);

  return (
    <div className="flex flex-col w-full my-4">
      <div className="flex items-center">
        <div className="bg-secondary w-6 h-6 flex items-center justify-center rounded-full">
          <img
            className="w-4 h-4 object-center"
            src={logo}
            alt="Milestones Page Logo Notification"
          />
        </div>
        <span className="uppercase ml-2 text-sm">Milestones Page</span>
        <span className="mx-1">&bull;</span>
        <span className="text-sm uppercase">{notification.relative_time}</span>
      </div>
      <div>
        <h3 className="text-base font-medium mt-2 mb-px">{title}</h3>
        <p title={message} className={messageClasses}>
          {message}.{" "}
          <Link
            onClick={onPreviewClick}
            className="text-secondary font-medium"
            to={`/notification?preview=${notification.id}`}
          >
            Click here to preview now
          </Link>
        </p>
      </div>
    </div>
  );
};

export default NotificationItemSystem;

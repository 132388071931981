import React, { useState } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import Input from "components/input/input";
import _ from "lodash";
import ModalHeader from "components/global/modal-header";
import TextArea from "components/textarea/textarea";
import CloudDownloadIconSVG from "assets/svg/cloud-download";
import PrintIconSVG from "assets/svg/print-icon";
import Divider from "components/divider/divider";
import InvoiceHeader from "./invoice-header";
import ContentHeader from "./content-header";
import InvoiceTable from "./invoice-table";

export default function InvoiceModal({ hide, userData, ...props }) {
  const [state, setState] = useState({
    loading: false,
    validationErrors: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleSubmit = () => {
    let payload = _.clone(state);
    delete payload.loading;
    delete payload.validationErrors;
    console.log("data => ", payload);
  };

  return (
    <Modal
      width="w-19/20 2md:w-17/20 3md:w-15/20 1xl:w-13/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
    >
      <div className="w-full 1md:flex">
        <div className="bg-modalGray w-full 1md:w-7/20">
          {/* header */}
          <ModalHeader title="Invoice" hide={hide} />
          {/* content */}
          <div className="px-10 pt-5 pb-9">
            <p className="text-xl font-semibold text-lightBlack tracking-very-tight mb-3">
              Send this Invoice
            </p>
            <Input
              inputContainerClass="form-input-container"
              value={state?.sentTo || ""}
              name="sentTo"
              outsideLabel="Sent to"
              placeholder="janedoe@gmail.com"
              outsideLabelClass="text-sm font-semibold text-lightBlack"
              noLabelPaddingY="0.8rem"
              type="email"
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.sentTo}
            />
            <Input
              inputContainerClass="form-input-container"
              value={state?.subject || ""}
              name="subject"
              outsideLabel="Subject"
              placeholder="Invoice #00000"
              outsideLabelClass="text-sm font-semibold text-lightBlack"
              noLabelPaddingY="0.8rem"
              type="text"
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.subject}
            />
            <TextArea
              label="Message"
              placeholder="Your Message"
              name="message"
              type="text"
              onChange={handleInputChange}
            />
            {/* footer */}
            <div className="w-full">
              <Button
                buttonName="Send"
                buttonTextClass="text-sm text-white font-semibold"
                buttonRadius="6px"
                buttonClass="relative bg-primary py-3 mb-3"
                buttonWidth="100%"
                onClick={handleSubmit}
              />
              <Divider text="or" />
              <div className="w-full mt-3 grid grid-cols-2 gap-x-3">
                <Button
                  buttonName="Download"
                  buttonTextClass="text-sm text-primary font-semibold flex items-center justify-center"
                  buttonRadius="6px"
                  buttonClass="relative bg-white border border-primary py-3"
                  icon={<CloudDownloadIconSVG className="mr-2 text-primary" />}
                  iconPosition="left"
                />
                <Button
                  buttonName="Print"
                  buttonTextClass="text-sm text-primary font-semibold flex items-center justify-center"
                  buttonRadius="6px"
                  buttonClass="relative bg-white border border-primary py-3"
                  icon={<PrintIconSVG className="mr-2 text-primary" />}
                  iconPosition="left"
                />
              </div>
            </div>
          </div>
        </div>
        {/* second column */}
        <div className="w-full 1md:w-13/20">
          {/* content */}
          <div className="px-16 py-14">
            <div className="w-full bg-white border border-invoiceGray">
              {/* header */}
              <InvoiceHeader invoice="12345678" date="14 April, 2022" />
              {/* content -header */}
              <ContentHeader
                taxTo="12345 6789 GB0001"
                taxFrom="12345 6789 US0001"
              />
              {/* content table */}
              <InvoiceTable />
              {/* summary */}
              <div className="w-full py-7 px-9">
                <div className="text-10 mb-6">
                  <p className="font-semibold">Payments:</p>
                  <p className="text-mediumGray">
                    $120.00 payment from Visa ···· 1234
                  </p>
                </div>
                <div className="text-10 mb-10">
                  <p className="font-semibold">Notes:</p>
                  <p className="text-mediumGray">Add a note...</p>
                </div>
              </div>
              {/* footer */}
              <div className="w-full py-3 px-9 bg-footerGray text-10 flex justify-between">
                <p className="font-semibold">
                  Thanks for being an Milestones customer!
                </p>
                <p className="font-semibold">
                  Need Help?
                  <span className="pl-3 font-normal text-mediumGray">
                    Send your concern at help@milestones.com
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

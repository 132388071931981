const SendAltIconSVG = ({ className = "", fill = "#EB469F" }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1989 0.710274C14.1222 0.633952 14.0253 0.581109 13.9196 0.557959C13.8139 0.534809 13.7038 0.542315 13.6023 0.579592L1.10227 5.12505C0.994471 5.16594 0.90166 5.23865 0.836166 5.33354C0.770672 5.42843 0.735596 5.541 0.735596 5.6563C0.735596 5.77159 0.770672 5.88416 0.836166 5.97905C0.90166 6.07394 0.994471 6.14666 1.10227 6.18755L6.55682 8.36936L8.73864 13.8239C8.77963 13.927 8.8499 14.0158 8.94076 14.0794C9.03163 14.143 9.13913 14.1786 9.25 14.1819C9.36482 14.1795 9.47624 14.1424 9.56955 14.0755C9.66287 14.0085 9.73371 13.9149 9.77273 13.8069L14.3182 1.30686C14.3569 1.20634 14.3662 1.09688 14.3451 0.991253C14.324 0.885624 14.2733 0.788177 14.1989 0.710274ZM9.25 12.0228L7.66477 8.0455L10.3864 5.32391L9.58523 4.52277L6.84091 7.26709L2.88636 5.65914L12.8466 2.06255L9.25 12.0228Z"
        fill={fill}
      />
    </svg>
  );
};

export default SendAltIconSVG;

import React from "react";
import Layout from "layouts/layout";

export default function Settings() {
  return (
    <Layout title="Settings" routeName="settings">
      <div className="main-page-wrapper">
        <p>Settings components add here ...</p>
      </div>
    </Layout>
  );
}

export default function InvoiceTable() {
  return (
    <div className="w-full">
      <table className="w-full">
        <thead className="text-10 font-semibold text-black">
          <tr>
            <th className="bg-smallGray py-3 px-9 w-9/20">PROJECT NAME</th>
            <th className="bg-smallGray py-3 px-9">QTY</th>
            <th className="bg-smallGray py-3 px-9">PRICE</th>
          </tr>
        </thead>
        <tbody className="text-xs text-black">
          {/*  empty*/}
          <tr>
            <td colSpan={3}></td>
          </tr>
          {/*  */}
          <tr>
            <td className="py-2 px-9 font-bold">Line Item Goes Here</td>
            <td className="py-2 px-9">1</td>
            <td className="py-2 px-9">$90.00</td>
          </tr>
          <tr>
            <td className="py-2 px-9 font-bold">Line Item Goes Here</td>
            <td className="py-2 px-9">1</td>
            <td className="py-2 px-9">$90.00</td>
          </tr>
        </tbody>
        <tfoot className="text-xs text-black">
          <tr>
            <td className="py-2 px-9">&nbsp;</td>
            <td className="py-2 px-9 border-t border-black">GST</td>
            <td className="py-2 px-9 border-t border-black">$10.00</td>
          </tr>
          <tr>
            <td className="py-2 px-9">&nbsp;</td>
            <td className="py-2 px-9">Comission (12%)</td>
            <td className="py-2 px-9">$10.00</td>
          </tr>
          <tr>
            <td className="py-2 px-9">&nbsp;</td>
            <td className="py-2 px-9 text-xs font-bold">Total</td>
            <td className="py-2 px-9 text-xs font-bold">$200.00</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

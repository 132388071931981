import React from "react";
import "./styles.css";

const ActionIconBtn = ({
  className = "action-btn",
  icon = null,
  disabled = false,
  hoverColor = "bg-gray",
  onClick = () => {},
}) => {
  return (
    <button
      className={className + ` ${!disabled && `hover:${hoverColor}`}`}
      disabled={disabled}
      onClick={onClick}
      style={{ opacity: disabled ? "0.5" : "1", cursor: disabled && "default" }}
    >
      {icon && icon}
    </button>
  );
};

export default ActionIconBtn;

import React from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import ModalHeader from "components/global/modal-header";

export default function TeamDeleteModal({ hide, userData, ...props }) {
  const { http } = global.services;

  const handleSubmit = async () => {
    console.log("submit delete => ", props);
    try {
      const { data } = await http.delete(`users/${props.id}`);
      if (data) {
        hide("delete");
      }
    } catch (err) {
      console.log("error@handleSubmit");
    }
  };

  const Footer = () => {
    return (
      <div className="relative flex flex-row items-center justify-end space-x-2">
        <Button
          key="cancel-btn-category"
          buttonName="Cancel"
          buttonClass="bg-thBg py-3 px-9"
          buttonTextClass="text-black text-sm font-bold"
          onClick={() => hide()}
        />
        <Button
          key="delete-btn"
          buttonName="Yes, Delete"
          buttonClass="bg-danger py-3 px-12"
          buttonTextClass="text-white text-sm font-bold"
          onClick={handleSubmit}
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="w-19/20 md:w-16/20 2md:w-12/20 3md:w-8/20 1xl:w-6/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-mutedGray"
    >
      <div className="w-full">
        {/* header */}
        <ModalHeader title="Delete Team Member" hide={hide} />
        {/* content */}
        <div className="pl-10 pr-7 py-7">
          <p className="text-lightBlack">
            Are you sure you want to delete team member
            <strong> John Doe (johndoe@gmail.com) </strong>from Milestones Page?
          </p>
        </div>
      </div>
    </Modal>
  );
}

const SearchIconSVG = ({ className = "", fill = "#C6C6C6" }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1701 22L13.0401 15.8717C10.2674 17.7007 6.56026 17.1349 4.45945 14.5621C2.35864 11.9893 2.54568 8.24422 4.89239 5.89353C7.24283 3.54609 10.9886 3.35825 13.5622 5.45877C16.1357 7.55929 16.7018 11.2665 14.8725 14.0393L21 20.1702L19.1701 22ZM9.47031 6.58788C7.6176 6.58671 6.02206 7.89433 5.65943 9.71108C5.2968 11.5278 6.26809 13.3477 7.97931 14.0578C9.69052 14.7679 11.6651 14.1704 12.6954 12.6307C13.7258 11.091 13.525 9.03801 12.2158 7.72714C11.4896 6.99594 10.5009 6.5857 9.47031 6.58788Z"
        fill={fill}
      />
    </svg>
  );
};

export default SearchIconSVG;

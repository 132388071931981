const MailIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 0H2.25C1.65326 0 1.08097 0.237053 0.65901 0.65901C0.237053 1.08097 0 1.65326 0 2.25V9.75C0 10.3467 0.237053 10.919 0.65901 11.341C1.08097 11.7629 1.65326 12 2.25 12H12.75C13.3467 12 13.919 11.7629 14.341 11.341C14.7629 10.919 15 10.3467 15 9.75V2.25C15 1.65326 14.7629 1.08097 14.341 0.65901C13.919 0.237053 13.3467 0 12.75 0ZM12.75 1.5L7.875 4.8525C7.76099 4.91833 7.63165 4.95298 7.5 4.95298C7.36835 4.95298 7.23902 4.91833 7.125 4.8525L2.25 1.5H12.75Z"
        fill="#5B5B5B"
      />
    </svg>
  );
};

export default MailIconSVG;

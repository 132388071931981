import React from "react";
import "./styles.css";

const colors = [
  "rgba(113, 59, 219, 0.05)",
  "rgba(51, 214, 159, 0.07)",
  "rgba(255, 76, 97, 0.05)",
  "rgba(76, 184, 255, 0.07)",
];

const subColors = ["rgba(255, 184, 0, 0.07)", "rgba(76, 184, 255, 0.07)"];

const Statistics = ({ items, number = 4, sub = false }) => {
  return (
    <div className={`statistics-container ${sub && "-mx-4"}`}>
      {items.map((item, index) => {
        return (
          <div
            key={index}
            className={`w-full px-4 my-2 2md:my-0 overflow-hidden 2md:w-1/${number}`}
          >
            {/* content */}
            <div className="statistics-card-item h-full rounded-xl">
              <div className="flex flex-row items-start space-x-6">
                <div>
                  <div
                    className="dashboard-icon-container rounded-full"
                    style={{
                      background: sub ? subColors[index] : colors[index],
                    }}
                  >
                    {item?.icon}
                  </div>
                </div>
                <div>
                  <p className="text-2xl font-bold text-lightBlack">
                    {item?.value}
                    {item.percent && (
                      <span className="pl-1 text-lightBlack opacity-50 text-xl">
                        <small>{`(${item?.percent})`}</small>
                      </span>
                    )}
                  </p>
                  <p className="text-darkerGray">{item?.label}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Statistics;

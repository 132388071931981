import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { subscribeActionAfter } from "redux-subscribe-action";
import { ADD_NOTIFICATION } from "redux/modules/notification/types";
import store from "redux/index";
import AlertNotification from "components/alerts/notification";
import toast from "react-hot-toast";
import { useNotifications } from "redux/modules/notification/hooks";


const NotificationPopupDock = () => {
  const { http } = global.services;
  const { notifications: allNotifications, addNotificationUserRead } =
    useNotifications();
  const [notifications, setNotifications] = useState([]);
  const element = document.querySelector("#notification");

  useEffect(() => {
    const unsub = subscribeActionAfter(ADD_NOTIFICATION, () => {
      const state = store.getState();
      const notification = state.notifications.push.at(0);
      if (notification) {
        setNotifications((prev) => [...prev, notification]);
      }
    });

    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (notifications.length) {
        setNotifications((prev) => prev.slice(1));
      }
    }, [10000]);

    return () => clearInterval(interval);
  }, [notifications]);

  const handleNotificationClose = (id) =>
    setNotifications((prev) => prev.filter((notif) => notif.id !== id));

  const handleNotificationOnRead = useCallback(
    async (id) => {
      try {
        const notification = allNotifications.find((notif) => notif.id === id);
        if (notification.isRead) return; // return immediately so we dont need to fire another request
        const { status } = await http.post("user-notifications", {
          notification_id: id,
        });
        if (status === 200) {
          addNotificationUserRead(id);
        }
      } catch (ex) {
        console.error(ex);
        toast.error(ex.data.data.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allNotifications]
  );

  const notificationsItems = notifications.map((notification) => (
    <AlertNotification
      className="mb-3"
      key={`popup-notif-${notification.id}`}
      onClose={() => handleNotificationClose(notification.id)}
      onRead={() => handleNotificationOnRead(notification.id)}
      data={notification}
    />
  ));

  return createPortal(
    <div className="fixed top-10 right-10 w-auto h-auto">
      {notificationsItems}
    </div>,
    element
  );
};

export default NotificationPopupDock;

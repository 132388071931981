import {
	SET_USER_DATA
} from './types';

const setUserData = (params) => {
	return {
    type: SET_USER_DATA,
    payload: params,
  };
}

const userActions = {
  setUserData
}

export default userActions;
export default function ModalHeader({
  padding = "pl-10 pr-7 py-3.5",
  className = "flex",
  title = "",
  hide = () => {},
}) {
  return (
    <div
      className={`${padding} border-b border-mutedGray ${className} justify-between items-center `}
    >
      <p className="text-2xl text-lightBlack font-bold tracking-tighter">
        {title}
      </p>
      {/* close */}
      <span className="close" onClick={() => hide()}></span>
    </div>
  );
}

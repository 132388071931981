import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "layouts/layout";
import Table from "components/table/table";
import ListContainer from "components/list/list-container";
import ListHeader from "components/list/list-header";
import _ from "lodash";
import { Profile1 } from "constants/images";
import ActionIconBtn from "components/button/action-btn";
import EditPencilIconSVG from "assets/svg/edit-pencil-icon";
import LockIconSVG from "assets/svg/lock-icon";
import TrashIconSVG from "assets/svg/trash-icon";
import TeamInviteModal from "components/teams/team-invite-modal";
import TeamDeleteModal from "components/teams/team-delete-modal";
import { useDialogHook } from "utils/customhooks";
import { urlSerializer } from "utils/utils";

import {
  SET_TEAM_MEMBERS,
  REFRESH_TEAM_MEMBERS,
} from "redux/modules/team-members/types";
import { useEffect } from "react";

export default function TeamMembers() {
  const { http } = global.services;

  const {
    initialize,
    refresh,
    page,
    limit,
    teamMembers = [],
    pages = [],
  } = useSelector((state) => state.teamMembers);

  const [filterValue, setFilterValue] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const modal = useDialogHook(TeamInviteModal);
  const _delete = useDialogHook(TeamDeleteModal);
  const dispatch = useDispatch();

  const getTeamMembers = async (selectedPage = null) => {
    try {
      const url = urlSerializer("team-members/list", {
        page: selectedPage ?? page,
        limit,
        search: filterValue,
      });
      const { data } = await http.get(url);

      const _loadMore = data.data.length > 0 ? true : false;
      // const _page = data.data.length > 0 ? page + 1 : page;
      const _page = data.current_page;
      const _lastPage = data.last_page;

      dispatch({
        type: SET_TEAM_MEMBERS,
        payload: {
          page: _page,
          lastPage: _lastPage,
          data: data.data,
          loadMore: _loadMore,
        },
      });
    } catch (err) {
      console.log("error@getTeamMembers", err);
    }
  };

  const handleResetPassword = async (email) => {
    try {
      const { data } = await http.post("auth/forgot-password", { email });
      console.log("handleResetPassword", data);
    } catch (err) {
      console.log("error@handleResetPassword", err);
    }
  };

  const handleAddEdit = (data = {}) => {
    modal(data, (result) => {
      if (!_.isEmpty(result)) {
        if (result === "addOrUpdate") {
          dispatch({
            type: REFRESH_TEAM_MEMBERS,
            payload: [],
          });
        }
      }
    });
  };

  const handleDelete = (data = {}) => {
    _delete(data, (result) => {
      if (!_.isEmpty(result)) {
        if (result === "delete") {
          dispatch({
            type: REFRESH_TEAM_MEMBERS,
            payload: [],
          });
        }
      }
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      nowrap: true,
      key: "name",
      render: (record) => (
        <div className="w-full flex items-center">
          {record.first_name && record.last_name ? (
            <>
              <img
                src={Profile1}
                alt="account"
                className="account-img mr-2.5"
              />
              <p>
                {record.first_name} {record.last_name}
              </p>
            </>
          ) : (
            <p>--</p>
          )}
        </div>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      nowrap: true,
      key: "phone_number",
    },
    {
      title: "Email",
      dataIndex: "email",
      nowrap: true,
      key: "email",
    },
    {
      title: "User Type",
      dataIndex: "role",
      nowrap: true,
      key: "role",
      render: (record) => (
        <p className={"capitalize"}>{record.role.replace("_", " ")}</p>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      hideSorting: true,
      nowrap: true,
      render: (record) => (
        <>
          <ActionIconBtn
            icon={<EditPencilIconSVG className="m-auto" />}
            onClick={() => handleAddEdit(record)}
          />
          <ActionIconBtn
            icon={<LockIconSVG className="m-auto" />}
            onClick={() => handleResetPassword(record.email)}
          />
          <ActionIconBtn
            hoverColor="bg-opacity-75"
            className="action-btn bg-red"
            icon={<TrashIconSVG className="m-auto" fill="white" />}
            onClick={() => handleDelete(record)}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (initialize) getTeamMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, initialize]);

  return (
    <Layout title="Team Members" routeName="teams">
      <div className="main-page-wrapper">
        <ListContainer
          header={
            <ListHeader
              title="List"
              countValue={_.size(teamMembers)}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              buttonName="+ Invite Member"
              showAddButton={true}
              onClickButton={() => handleAddEdit()}
            />
          }
        >
          <Table
            columns={columns}
            dataSource={teamMembers}
            pagination={true}
            selectable={true}
            selected={selectedRows}
            onSelected={(selectedRows) => {
              setSelectedRows(selectedRows);
            }}
            perPage={10}
            currentPage={page}
            onHandleChangePage={getTeamMembers}
            pages={pages}
          />
        </ListContainer>
      </div>
    </Layout>
  );
}

const SortIconSVG = ({ className = "", fill = "#C4C4C4" }) => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 0L7.4641 4.5H0.535898L4 0Z" fill={fill} />
    <path d="M4 12L7.4641 7.5H0.535898L4 12Z" fill={fill} />
  </svg>
);
export default SortIconSVG;

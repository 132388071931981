import React, { useState, useEffect } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import _ from "lodash";
import ModalHeader from "components/global/modal-header";
import Input from "components/input/input";
import PhoneIconSVG from "assets/svg/phone-icon";
import MailIconSVG from "assets/svg/mail-icon";
import Radio from "components/radio/radio";
import FileUpload from "components/file-upload/file-upload";
import { FaUser } from "react-icons/fa";

const headers = {
  headers: {
    "content-type": "multipart/form-data",
  },
  mode: "no-cors",
};

export default function TeamInviteModal({ hide, userData, ...props }) {
  const { http } = global.services;

  const [state, setState] = useState({
    role: "super_admin",
    loading: false,
    validationErrors: null,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props && props.id) {
      setState((prev) => ({
        ...prev,
        first_name: props.first_name,
        last_name: props.last_name,
        phone_number: props.phone_number,
        email: props.email,
        role: props.role,
        id: props.id,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleSubmit = async () => {
    let error = "";
    try {
      let payload = _.clone(state);
      console.log("data => ", payload);
      let formData = new FormData();
      if (state.first_name) formData.append("first_name", payload.first_name);
      if (state.last_name) formData.append("last_name", payload.last_name);
      if (state.email) formData.append("email", payload.email);
      if (state.phone_number)
        formData.append("phone_number", payload.phone_number);
      if (state.role) formData.append("role", payload.role);
      if (state.photo) formData.append("photo", payload.photo);
      if (props.id) formData.append("id", payload.id);

      const url = props.id ? `team-members/${props.id}` : `team-members/invite`;

      const { data } = await http.post(url, formData, headers);
      if (data) {
        hide("addOrUpdate");
      }
    } catch (err) {
      const { data, status } = err;
      if (status === 422) {
        error = data.errors;
      }
    }
    setErrors(error || {});
  };

  const onUploadImage = async (e) => {
    const file = e.target.files[0];
    state.photo = file;
  };

  const Footer = () => {
    return (
      <div className="relative flex flex-row items-center justify-end space-x-2">
        <Button
          key="cancel-btn"
          buttonName="Cancel"
          buttonClass="bg-thBg py-3 px-9"
          buttonTextClass="text-black text-sm font-bold"
          onClick={() => hide()}
        />
        <Button
          key="save-btn"
          buttonName={props?.id ? "Save" : "Invite"}
          buttonClass="bg-primary py-3 px-12"
          buttonTextClass="text-white text-sm font-bold"
          onClick={handleSubmit}
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="w-19/20 md:w-16/20 2md:w-11/20 3md:w-9/20 1xl:w-7/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-mutedGray"
    >
      <div className="w-full">
        {/* header */}
        <ModalHeader
          title={props?.id ? "Edit Team User" : "Invite Team Member"}
          hide={hide}
        />
        {/* content */}
        <div className="pl-10 pr-7 py-3.5">
          <FileUpload
            iconLeft={
              <FaUser className="text-4xl" style={{ color: "#DDDDDD" }} />
            }
            wrapperClass="mt-3"
            rightContainerClass="px-6 py-1"
            rightContentClass="my-0"
            accept="image/*" // default: null, files to accept
            width={"100%"} // default: 100%, width for right section label
            image_url={null} // default: null, image fetched when uploaded to server
            uploading={false} // default: false, upload loader
            onChange={onUploadImage}
            name="member-photo"
            removable={true}
          />
          <div className="w-full mt-3">
            <div className="w-full 1sm:flex 1sm:space-x-5">
              <Input
                inputContainerClass="form-input-container"
                value={state.first_name || ""}
                name="first_name"
                outsideLabel="First Name"
                placeholder="Enter First Name"
                outsideLabelClass="text-sm font-semibold text-lightBlack"
                noLabelPaddingY="0.8rem"
                type="text"
                onChange={handleInputChange}
                errorMessage={errors.first_name}
                errorMessagePosition="bottom"
              />
              <Input
                inputContainerClass="form-input-container"
                value={state.last_name || ""}
                name="last_name"
                outsideLabel="Last Name"
                placeholder="Enter Last Name"
                outsideLabelClass="text-sm font-semibold text-lightBlack"
                noLabelPaddingY="0.8rem"
                type="text"
                onChange={handleInputChange}
                errorMessage={errors.last_name}
                errorMessagePosition="bottom"
              />
            </div>
            <div className="w-full 1sm:flex 1sm:space-x-5">
              <Input
                inputContainerClass="form-input-container"
                value={state.phone_number || ""}
                name="phone_number"
                outsideLabel="Phone Number"
                placeholder="Enter Phone Number"
                outsideLabelClass="text-sm font-semibold text-lightBlack"
                noLabelPaddingY="0.8rem"
                type="text"
                onChange={handleInputChange}
                errorMessage={errors.phone_number}
                errorMessagePosition="bottom"
                inputIcon={<PhoneIconSVG />}
              />
              <Input
                inputContainerClass="form-input-container"
                value={state.email || ""}
                name="email"
                outsideLabel="Email Address"
                placeholder="Enter Email Address"
                outsideLabelClass="text-sm font-semibold text-lightBlack"
                noLabelPaddingY="0.8rem"
                type="email"
                onChange={handleInputChange}
                errorMessage={errors.email}
                errorMessagePosition="bottom"
                inputIcon={<MailIconSVG />}
              />
            </div>

            <div className="w-full">
              <p className="text-sm text-lightBlack font-semibold">User Type</p>
              <div className="mt-3 grid gap-y-3">
                <Radio
                  label="Super Admin"
                  name="role"
                  checked={state.role === "super_admin" ? true : false}
                  defaultChecked={state.role === "super_admin" ? true : false}
                  active={state.role === "super_admin" ? true : false}
                  onChange={() =>
                    handleInputChange({
                      target: { name: "role", value: "super_admin" },
                    })
                  }
                />
                <Radio
                  label="Admin"
                  name="role"
                  checked={state.role === "admin" ? true : false}
                  defaultChecked={state.role === "admin" ? true : false}
                  active={state.role === "admin" ? true : false}
                  onChange={() =>
                    handleInputChange({
                      target: { name: "role", value: "admin" },
                    })
                  }
                />
                <Radio
                  label="Read Only Admin"
                  name="role"
                  checked={state.role === "read_only_admin" ? true : false}
                  defaultChecked={
                    state.role === "read_only_admin" ? true : false
                  }
                  active={state.role === "read_only_admin" ? true : false}
                  onChange={() =>
                    handleInputChange({
                      target: { name: "role", value: "read_only_admin" },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

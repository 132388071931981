const CloudDownloadIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2826_2043099)">
        <path
          d="M6.88635 15.1364L9.72725 17.4091M9.72725 17.4091L12.5682 15.1364M9.72725 17.4091V9.45455M5.74998 12.8636C0.636345 13.4318 1.20453 7.18182 5.74998 7.75C4.04544 1.5 13.7045 1.5 13.1363 6.04546C18.8182 4.34091 18.8182 13.4318 13.7045 12.8636H5.74998Z"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2826_2043099">
          <rect
            width="18.1818"
            height="18.1818"
            fill="white"
            transform="translate(0.636353 0.363647)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloudDownloadIconSVG;

const PhoneIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4998 5.00008H9.4998C9.49941 4.60236 9.34124 4.22105 9.06001 3.93982C8.77878 3.6586 8.39747 3.50043 7.99976 3.50003V2.5C8.66257 2.50079 9.29802 2.76445 9.7667 3.23313C10.2354 3.70182 10.499 4.33726 10.4998 5.00008Z"
        fill="#5B5B5B"
      />
      <path
        d="M12.4999 5.00014H11.4999C11.4988 4.07218 11.1297 3.18253 10.4735 2.52636C9.81737 1.87019 8.92772 1.50109 7.99976 1.50003V0.5C9.19286 0.501323 10.3367 0.975869 11.1804 1.81952C12.024 2.66318 12.4986 3.80704 12.4999 5.00014Z"
        fill="#5B5B5B"
      />
      <path
        d="M8.6663 9.2413L9.78633 8.12126C9.93626 7.9713 10.1269 7.86863 10.3346 7.82597C10.5423 7.78331 10.758 7.80253 10.9549 7.88126L12.3189 8.42727C12.52 8.50771 12.6923 8.64655 12.8137 8.82587C12.9351 9.00519 12.9999 9.21676 12.9999 9.4333V11.9139C13.0003 12.0613 12.9705 12.2073 12.9125 12.3428C12.8545 12.4784 12.7695 12.6006 12.6625 12.7022C12.5556 12.8037 12.4291 12.8823 12.2908 12.9333C12.1524 12.9842 12.0051 13.0064 11.8579 12.9984C2.29659 12.4034 0.365533 4.30514 0.0070213 1.20404C-0.0103424 1.05244 0.00458761 0.89887 0.0508296 0.753447C0.0970716 0.608024 0.173578 0.474039 0.275315 0.360304C0.377053 0.246569 0.501718 0.155662 0.641108 0.0935627C0.780499 0.031463 0.931458 -0.00042223 1.08406 4.22185e-06H3.52063C3.73717 -2.73139e-06 3.94875 0.064872 4.12807 0.18626C4.30739 0.307648 4.44622 0.47998 4.52666 0.681026L5.07218 2.04507C5.15091 2.24196 5.17013 2.45763 5.12747 2.66534C5.0848 2.87305 4.98213 3.06368 4.83217 3.21361L3.71214 4.33364C3.71214 4.33364 4.33316 8.69978 8.6663 9.2413Z"
        fill="#5B5B5B"
      />
    </svg>
  );
};

export default PhoneIconSVG;

import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const EarningCharts = ({ labels, datasets }) => {
  const options = {
    responsive: true,
    categoryPercentage: 0.8,
    barPercentage: 0.4,
    tickBorderDash: [10, 10],
    scales: {
      x: {
        grid: {
          display: false,
          zeroLineColor: "transparent",
          borderWidth: 0,
        },
      },
      y: {
        grid: {
          borderDash: [8, 4],
          color: "#EDEFF5",
          borderWidth: 0,
        },
      },
    },
  };

  const data = useMemo(() => {
    return {
      labels,
      datasets: datasets,
    };
  }, [labels, datasets]);

  return (
    <div className="w-full">
      <Bar options={options} data={data} />
    </div>
  );
};

export default EarningCharts;

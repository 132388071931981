import React, { useState, useEffect } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import _ from "lodash";
import ModalHeader from "components/global/modal-header";
import Input from "components/input/input";
import PhoneIconSVG from "assets/svg/phone-icon";
import MailIconSVG from "assets/svg/mail-icon";
import LockIconSVG from "assets/svg/lock-icon";
import UserCheckIconSVG from "assets/svg/user-check-icon";
import BlockIconSVG from "assets/svg/block-icon";

export default function UserInviteModal({ hide, userData, ...props }) {
  const { http } = global.services;

  const [state, setState] = useState({
    loading: false,
    validationErrors: null,
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleSubmit = async () => {
    let error = "";
    try {
      let payload = _.clone(state);
      delete payload.loading;
      delete payload.validationErrors;
      console.log("data => ", payload);

      const { data } = props.id
        ? await http.put(`users/${props.id}`, payload)
        : await http.post("users/invite", payload);

      console.log("handleSubmit", data);
      hide("addOrUpdate");
    } catch (err) {
      const { data, status } = err;
      if (status === 422) {
        error = data.errors;
      }
    }
    setErrors(error || {});
  };

  const handleChangeStatus = async (status) => {
    try {
      const { data } = await http.put(`users/${props.id}/status/${status}`);
      console.log("handleChangeStatus", data);
      hide("addOrUpdate");
    } catch (err) {
      console.log("error@handleChangeStatus", err);
    }
  };

  const handleResetPassword = async () => {
    try {
      const { data } = await http.post("auth/forgot-password", {
        email: state.email,
      });
      console.log("handleResetPassword", data);
      hide();
    } catch (err) {
      console.log("error@handleResetPassword", err);
    }
  };

  const Footer = () => {
    return (
      <div className="relative flex flex-row items-center justify-end space-x-2">
        <Button
          key="cancel-btn"
          buttonName="Cancel"
          buttonClass="bg-thBg py-3 px-9"
          buttonTextClass="text-black text-sm font-bold"
          onClick={() => hide()}
        />
        <Button
          key="save-btn"
          buttonName={props?.id ? "Save" : "Invite"}
          buttonClass="bg-primary py-3 px-12"
          buttonTextClass="text-white text-sm font-bold"
          onClick={handleSubmit}
        />
      </div>
    );
  };

  useEffect(() => {
    if (props && props.id) {
      setState((prev) => ({
        ...prev,
        id: props.id,
        first_name: props.first_name,
        last_name: props.last_name,
        phone_number: props.phone_number,
        email: props.email,
        status: props.status,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);

  return (
    <Modal
      footer={<Footer />}
      width="w-19/20 md:w-16/20 2md:w-11/20 3md:w-9/20 1xl:w-7/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-mutedGray"
    >
      <div className="w-full">
        {/* header */}
        <ModalHeader
          title={props?.id ? "Edit User" : "Invite User"}
          hide={hide}
        />
        {/* content */}
        <div className="pl-10 pr-7 py-3.5">
          <div className="w-full mt-3">
            <div className="w-full 1sm:flex 1sm:space-x-5">
              <Input
                inputContainerClass="form-input-container"
                value={state?.first_name || ""}
                name="first_name"
                outsideLabel="First Name"
                placeholder="Enter First Name"
                outsideLabelClass="text-sm font-semibold text-lightBlack"
                noLabelPaddingY="0.8rem"
                type="text"
                onChange={handleInputChange}
                errorMessage={errors?.first_name}
                errorMessagePosition="bottom"
              />
              <Input
                inputContainerClass="form-input-container"
                value={state?.last_name || ""}
                name="last_name"
                outsideLabel="Last Name"
                placeholder="Enter Last Name"
                outsideLabelClass="text-sm font-semibold text-lightBlack"
                noLabelPaddingY="0.8rem"
                type="text"
                onChange={handleInputChange}
                errorMessage={errors?.last_name}
                errorMessagePosition="bottom"
              />
            </div>
            <div className="w-full 1sm:flex 1sm:space-x-5">
              <Input
                inputContainerClass="form-input-container"
                value={state?.phone_number || ""}
                name="phone_number"
                outsideLabel="Phone Number"
                placeholder="Enter Phone Number"
                outsideLabelClass="text-sm font-semibold text-lightBlack"
                noLabelPaddingY="0.8rem"
                type="text"
                onChange={handleInputChange}
                errorMessage={errors?.phone_number}
                inputIcon={<PhoneIconSVG />}
                errorMessagePosition="bottom"
              />
              <Input
                inputContainerClass="form-input-container"
                value={state?.email || ""}
                name="email"
                outsideLabel="Email Address"
                placeholder="Enter Email Address"
                outsideLabelClass="text-sm font-semibold text-lightBlack"
                noLabelPaddingY="0.8rem"
                type="email"
                onChange={handleInputChange}
                errorMessage={errors?.email}
                inputIcon={<MailIconSVG />}
                errorMessagePosition="bottom"
              />
            </div>
            {props?.id && (
              <div className="w-full 1sm:flex 1sm:space-x-3">
                <Input
                  inputContainerClass="form-input-container"
                  value={props?.status || ""}
                  name="status"
                  outsideLabel="Status"
                  outsideLabelClass="text-sm font-semibold text-lightBlack"
                  noLabelPaddingY="0.8rem"
                  type="text"
                  disabledInput={true}
                />
                {/* empty */}
                <div className="w-1/2">&nbsp;</div>
                {/* empty */}
              </div>
            )}
            {props?.id && (
              <div className="mt-10 w-full grid gap-y-3 md:gap-y-0 md:flex md:justify-between">
                <Button
                  key="reset-btn"
                  buttonName="Reset Password"
                  buttonClass="bg-thBg py-3 px-9"
                  buttonTextClass="flex items-center justify-center text-lightBlack text-sm font-bold"
                  onClick={() => handleResetPassword()}
                  iconPosition="left"
                  icon={<LockIconSVG className="mr-2.5" />}
                />
                <Button
                  key="block-btn"
                  buttonName="Block"
                  buttonClass="bg-thBg py-3 px-9"
                  buttonTextClass="flex items-center justify-center text-lightBlack text-sm font-bold"
                  onClick={() => handleChangeStatus("blocked")}
                  iconPosition="left"
                  icon={<BlockIconSVG className="mr-2.5" />}
                />
                <Button
                  key="approve-btn"
                  buttonName="Approve"
                  buttonClass="bg-thBg py-3 px-9"
                  buttonTextClass="flex items-center justify-center text-lightBlack text-sm font-bold"
                  onClick={() => handleChangeStatus("verified")}
                  iconPosition="left"
                  icon={<UserCheckIconSVG className="mr-2.5" />}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

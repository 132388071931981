import Logo from "assets/images/logo-white.png";

export default function InvoiceHeader({
  address = ["Address Line 1", "City, State, Zip Code", "ABN No.0000001"],
  invoice = "12345678",
  date = "14 April, 2022",
}) {
  return (
    <div className="w-full bg-primary text-white py-7 px-9 grid grid-cols-2">
      <div className="w-full">
        <img src={Logo} alt="milestones-logo" className="logo-invoice" />
        <p className="text-10 mt-6">
          <strong>Milestones Page</strong>
          <br />
          {address[0]}
          <br />
          {address[1]}
          <br />
          {address[2]}
        </p>
      </div>
      <div className="w-full">
        <h1 className="text-2xl tracking-tighter">Invoice</h1>
        <p className="text-10 font-semibold mt-6">
          Invoice Number: <br />
          <span className="font-normal">{invoice}</span>
        </p>
        <p className="text-10 font-semibold mt-6">
          Date: <br />
          <span className="font-normal">{date}</span>
        </p>
      </div>
    </div>
  );
}

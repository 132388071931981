import { SET_FAQS, GET_FAQS, REFRESH_FAQS } from "./types";
const initialState = {
  initialize: true,
  refresh: false,
  isRequesting: false,
  loadMore: true,
  faqs: [],
  limit: 10,
  page: 1,
  pages: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REFRESH_FAQS:
      return {
        ...state,
        limit: 10,
        page: 1,
        initialize: true,
        refresh: true,
        isRequesting: false,
        loadMore: true,
        faqs: [],
      };

    case GET_FAQS:
      return {
        ...state,
        refresh: false,
        isRequesting: true,
      };

    case SET_FAQS:
      // let newFaqs = state.faqs;

      // payload.data.forEach((f) => {
      //   const faq = state.faqs.filter((sf) => sf.id === f.id);
      //   if (!faq.length) {
      //     newFaqs.push(f);
      //   }
      // });

      const tempPages = [];

      if(payload.lastPage > 0 && state.lastPage != payload.lastPage) {
        for(let i = 1; i <= payload.lastPage; i++) {
          tempPages.push(i);
        }
      }

      return {
        ...state,
        isRequesting: false,
        initialize: false,
        page: payload.page,
        loadMore: payload.loadMore,
        // faqs: newFaqs, // Use this to add more items on the current list when using a load more button or an intersection to load more items.
        categfaqsories: payload.data,
        pages: tempPages.length > 0 ? tempPages : state.pages
      };

    default:
      return state;
  }
};

export default reducer;

import React from "react";
import Header from "components/global/header";
import Footer from "components/global/footer";
import SideBar from "components/global/sidebar";
import Alerts from "components/global/alerts";
import { connect } from "react-redux";
import userActions from "redux/modules/user/actions";
import { Helmet, HelmetProvider } from "react-helmet-async";

function Layout({
  children,
  title = null,
  routeName = "",
  userData,
  setUserData,
  className=""
}) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title} - Milestones Page Administrator</title>
      </Helmet>
      <div className="h-screen lg:flex w-full relative">
        <SideBar routeName={routeName} />
        <div className="h-screen lg:static lg:overflow-y-auto lg:overflow-x-hidden lg:flex-grow bg-siteBg custom-scroll">
          {userData && (
            <Header
              title={title}
              userData={userData}
              setUserData={setUserData}
            />
          )}
          {userData && routeName === "dashboard" && <Alerts />}
          <main className={`main-wrapper mx-auto flex flex-1 bg-siteBg ${className}`}>
            {children}
          </main>
          <Footer />
        </div>
      </div>
    </HelmetProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

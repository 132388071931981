import {csx} from 'utils/utils'


const NotificationItem = ({
  className,
  children,
  isRead,
  onRead = () => { },
  ...props
}) => {

  const classes = csx([
    "flex items-center justify-between px-5 py-3 text-sm border-b border-gray max-h-36 oveflow-hidden",
    !isRead && "bg-blue bg-opacity-10",
    className,
  ]);

  return (
    <li className={classes} {...props} onMouseEnter={onRead}>
      {children}
    </li>
  );
};

export default NotificationItem

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Layout from "layouts/layout";
import Table from "components/table/table";
import ListContainer from "components/list/list-container";
import ListHeader from "components/list/list-header";
import _ from "lodash";
import { Profile1 } from "constants/images";
import ActionIconBtn from "components/button/action-btn";
import EditPencilIconSVG from "assets/svg/edit-pencil-icon";
import moment from "moment";
import CategoryDeleteModal from "components/categories/category-delete-modal";
import { useDialogHook } from "utils/customhooks";
import CategoryAddModal from "components/categories/category-add-modal";
import { urlSerializer } from "utils/utils";

import {
  REFRESH_CATEGORIES,
  GET_CATEGORIES,
  SET_CATEGORIES,
} from "redux/modules/category-management/types";

export default function Categories() {
  const { http } = global.services;
  const {
    limit,
    page,
    categories = [],
    initialize,
    refresh,
    loadMore,
    isRequesting,
    pages = [],
  } = useSelector((state) => state.category);
  const query = useQuery();
  const searchValue = query.get("search");
  const [filterValue, setFilterValue] = useState(searchValue);
  const [selectedRows, setSelectedRows] = useState([]);
  const modal = useDialogHook(CategoryAddModal);
  const _delete = useDialogHook(CategoryDeleteModal);
  const dispatch = useDispatch();

  const getCategories = async (selectedPage = null) => {
    if (isRequesting || !loadMore) return;

    dispatch({ type: GET_CATEGORIES, payload: [] });

    try {
      const url = urlSerializer("categories/list", {
        search: filterValue,
        page: selectedPage ?? page,
        limit: limit,
      });
      const { data } = await http.get(url);
      const _loadMore = data.data.length > 0 ? true : false;
      // const _page = data.data.length > 0 ? page + 1 : page;
      const _page = data.current_page;
      const _lastPage = data.last_page;

      dispatch({
        type: SET_CATEGORIES,
        payload: {
          data: data.data,
          loadMore: _loadMore,
          page: _page,
          lastPage: _lastPage,
        },
      });
    } catch (err) {
      console.log("error@getCategories", err);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();

    dispatch({
      type: REFRESH_CATEGORIES,
      payload: [],
    });
  };

  const handleAddEdit = (data = {}) => {
    modal(data, (result) => {
      if (!_.isEmpty(result)) {
        if (result === "add") {
          dispatch({
            type: REFRESH_CATEGORIES,
            payload: [],
          });
        } else if (result === "delete") {
          handleDelete(data);
        }
      }
    });
  };

  const handleDelete = (data = {}) => {
    _delete(data, (result) => {
      if (!_.isEmpty(result)) {
        console.log("result ", result);
        if (result === "delete") {
          dispatch({
            type: REFRESH_CATEGORIES,
            payload: [],
          });
        }
      }
    });
  };

  const columns = [
    // {
    //   title: "ID No.",
    //   dataIndex: "name",
    //   nowrap: true,
    //   key: "name",
    //   render: (record) => (
    //     <div className="w-full flex items-center">
    //           <p>{record.id}</p>
    //     </div>
    //   ),
    // },
    {
      title: "Category Title",
      dataIndex: "name",
      nowrap: true,
      key: "name",
      render: (record) => (
        <div className="w-full flex items-center">
          {record.name ? (
            <>
              {/* <img
                src={Profile1}
                alt="account"
                className="account-img mr-2.5"
              /> */}
              <p>{record.name}</p>
            </>
          ) : (
            <p>--</p>
          )}
        </div>
      ),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   nowrap: true,
    //   key: "description",
    //   render: (record) => (
    //     <p>
    //       {_.truncate(record?.description, {
    //         length: 71,
    //       })}
    //     </p>
    //   ),
    // },
    // {
    //   title: "Group Category",
    //   dataIndex: "group",
    //   nowrap: true,
    //   key: "group",
    // },
    // {
    //   title: "Date Created",
    //   dataIndex: "created_at",
    //   nowrap: true,
    //   key: "dateCreated",
    //   render: (record) => (
    //     <p>{moment(record.created_at).format("MMMM DD, YYYY")}</p>
    //   ),
    // },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      hideSorting: true,
      nowrap: true,
      render: (record) => (
        <>
          <ActionIconBtn
            className="action-btn-round"
            icon={<EditPencilIconSVG className="m-auto" />}
            onClick={() => handleAddEdit(record)}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (initialize) getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, initialize]);

  return (
    <Layout title="Categories" routeName="categories">
      <div className="main-page-wrapper">
        <ListContainer
          header={
            <ListHeader
              title="List"
              countValue={_.size(categories)}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              buttonName="+ Add Category"
              showAddButton={true}
              onClickButton={() => handleAddEdit()}
              onSearch={(e) => handleSearch(e)}
              filter ={false}
            />
          }
        >
          <Table
            columns={columns}
            dataSource={categories}
            pagination={true}
            selectable={true}
            selected={selectedRows}
            onSelected={(selectedRows) => {
              setSelectedRows(selectedRows);
            }}
            perPage={10}
            currentPage={page}
            onHandleChangePage={getCategories}
            pages={pages}
          />
        </ListContainer>
      </div>
    </Layout>
  );
}

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

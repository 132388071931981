export default function TextArea({
  label = "",
  labelClass = "text-sm font-semibold text-lightBlack",
  rows = 4,
  errorMessage,
  ...props // make sure this pops here is for input only
}) {
  return (
    <div className="form-input-container">
      {label && (
        <label htmlFor={label} className={labelClass}>
          {label}
        </label>
      )}
      <textarea
        className={`w-full ${label && "mt-2"}`}
        style={{
          borderColor: errorMessage && "red"
        }}
        type="text"
        rows={rows}
        {...props}
      />

      {errorMessage && (
        <p className="input-error-message -mb-1">* {errorMessage}</p>
      )}
    </div>
  );
}

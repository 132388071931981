import {
    SET_REPORT_MILESTONES,
    GET_REPORT_MILESTONES,
    REFRESH_REPORT_MILESTONES,
} from "./types";

const initialState = {
  page: 1,
  limit: 15,
  initialize: true,
  refresh: false,
  isRequesting: false,
  loadMore: true,
  reportMilestones: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REFRESH_REPORT_MILESTONES:
      return {
        ...state,
        page: 1,
        limit: 15,
        initialize: true,
        refresh: true,
        isRequesting: false,
        loadMore: true,
        reportMilestones: [],
      };

    case GET_REPORT_MILESTONES:
      return {
        ...state,
        refresh: false,
        isRequesting: true,
      };

    case SET_REPORT_MILESTONES:
      return {
        ...state,
        isRequesting: false,
        initialize: false,
        page: payload.page,
        loadMore: payload.loadMore,
        reportMilestones: [...state.reportMilestones, ...payload.data],
      };

    default:
      return state;
  }
};

export default reducer;

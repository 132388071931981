import React from "react";
import Modal from "components/global/modal"
import ModalHeader from "components/global/modal-header";
import Button from "components/button/button"; 
import toast from "react-hot-toast";
import { useState } from "react";
import LoadingIndicator from "assets/svg/loading-indicator";
import TextArea from "components/textarea/textarea";
const ModalReject = ({info,hide=()=>{}, ...props}) =>{ 
    const {team_name,id} = info;
    const { http } = global.services;
    const [isLoading,setIsLoading] = useState(false);
    const [reason, setReason] = useState('')
    const handleUpdate = async()=>{
        if(reason === '') return;
        try{
          const payload = {
            id:id,
            reject_reason:reason,
            team_status:'Rejected'
          }
          setIsLoading(true) 
          const {data } = await http.post(`group/status`,payload);
          toast.success(data.message);
          hide({success:true, data:data});
        }catch(err){
          toast.error(`It appears that something went wrong`); 
        }finally{
          hide({success:false});
        }

    }

    const handleInputChange = (e) => {
      const {value } = e.target;
      setReason(value);
    };

    const Footer = () => {
        return (
          <div className="relative flex flex-row items-center justify-end space-x-2">
            <Button
              key="cancel-btn-category"
              buttonName="Cancel"
              buttonClass="bg-thBg py-3 px-9"
              buttonTextClass="text-black text-xs font-bold"
              onClick={() => hide()} 
            />
            <Button 
              disabled={isLoading}
              key="delete-btn"
              buttonName="Yes, Delete"
              buttonClass="bg-danger py-3 px-8 "
              buttonTextClass="text-white text-xs font-bold capitalize flex"
              onClick={handleUpdate}
              loadingIcon={
                isLoading ? (
                <LoadingIndicator className="text-white w-4 h-4 ml-2" />
                ) : null
            }
            />
          </div>
        );
      };

    return (
        <Modal
        footer={<Footer />}
        width="w-16/20 md:w-13/20 2md:w-9/20 3md:w-6/20 1xl:w-5/20"
        contentClassName="rounded-lg overflow-auto custom-scroll"
        wrapperClassName="overflow-visible"
        footerClass="border-t border-mutedGray"
        >
            <ModalHeader
            title="Reject Group Request"
            hide={hide}
            />

            <div className="px-4 py-4">
                <p className="text-lightBlack">
                    Are you sure you want to reject group '<span className="font-bold">{team_name}</span>' ?
                </p>
                <h4 className="text-xs m-2">
                    Reaseon for Rejecting
                </h4>
                <TextArea 
                  placeholder="Enter your reasoning"
                  name="message"
                  type="text" 
                  onChange={handleInputChange}
                  value={reason}
                />
            </div>
        </Modal>
    )
}

export default ModalReject;
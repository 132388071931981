import React, { useEffect, useState, useLayoutEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux"; 
import Select from "components/select/select";
import { urlSerializer } from "utils/utils";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"; 
import { Bar } from "react-chartjs-2"; 

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

const NetNewMRR = ()=>{ 
    const { http } = global.services; 
    const [loading, setLoading] = useState(false);
    const [dateSelected, setDateSelected] = useState({
        year:new Date().getFullYear()
    });
    const [state, stateUse] = useState({
        total_new_net_mrr:{},
        total_per_month_new_mrr:{},
        total_per_month_churn:{},
    });

    const getProfileViews = async () => {
        setLoading(true); 
        try {
          const url = urlSerializer("reports/net-new-mrr", { 
            year: dateSelected.year
          });
          const { data } = await http.get(url);
          console.log(data);
          stateUse(data);
        //   const profileViews = {
        //     results: data.results,
        //     today: data.today,
        //     past30: data.past_30,
        //     total: data.total,
        //   };
        //   dispatch({
        //     type: SET_PROFILE_VIEWS,
        //     payload: profileViews,
        //   });
        } catch (err) {
          console.log("getProfileViews", err);
        } finally {
          setLoading(false);
        }
    };

    const yearOption = ()=>{
        let year = []
        for(let x = 2020; x <= 2035; x++){
            year.push({
                label:x,
                value:x
            });
        } 
        return year;
    };
    

    useEffect(()=>{
        getProfileViews();
      },[dateSelected])

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDateSelected((prev) => ({
          ...prev,
          [name]: value, 
        }));
      }; 
    

    return (
        <>
        <div className="w-full bg-white p-2 rounded-md">
            {/* headers */}
            <div className="page-view-heading w-full 2sm:flex 2sm:justify-between 2sm:items-center space">
              <div className="w-full flex flex-row gap-x-2 items-center">
                <h1 className="font-bold text-lightBlack">
                    Net New MRR
                </h1> 
              </div>

              <div className="w-full mt-4 3md:w-6/20">  
              <Select
                  options={yearOption()}
                  placeholder="Select Year"
                  onChange={(e) => handleInputChange({ target: { name: "year", value: e.value } })}
                  height={40}
                  backgroundColor="#F2F2F2"
                  borderColor="#F2F2F2"
                  isDisabled={loading}
                  value={yearOption().filter((opt) => opt.value === dateSelected.year)}
                />
              </div>
            </div>
                <div className="page-view-graph mt-7.5 w-full">  
                    <StackGraph
                    serverData={state || []}  
                    /> 
                </div>
            {
                Object.keys(state?.total_new_net_mrr).length > 0 &&
                <div className="flex ml-2 mr-2 w-full p-2">
                    {Object.entries(state?.total_new_net_mrr).map(([month, value]) => (
                        <div key={month} className=" month-data w-full border border-red-600 flex justify-center bg-gray-200"> 
                        <div>
                            <p className="text-sm">$ {parseFloat(value).toFixed(2)}</p>
                            <h2 className="text-center text-sm">{month}</h2>
                        </div>
                        </div>
                    ))}
                </div>
            } 
            </div>
        </>
    );
    
}

function StackGraph(serverData = []) {  
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const mrr  = serverData?.serverData || [];
    const new_mrr  = mrr?.total_per_month_new_mrr ? labels.map(label => mrr?.total_per_month_new_mrr[label]) : [];
    const churn_mrr  = mrr?.total_per_month_churn ? labels.map(label => parseFloat(mrr?.total_per_month_churn[label]) * -1 ) : []; 
    const data = {
        labels,
        datasets: [
        {
            label: 'Cancellation MRR',
            data:  churn_mrr,
            backgroundColor: 'rgb(220,20,60)',
        }, 
          {
            label: 'New MRR',
            data:  new_mrr,
            backgroundColor: 'rgb(53, 162, 235)',
          },
          
        ],
      };
  
    const options = {
        plugins: {
          // datalabels: {
          //   anchor: 'end',
          //   align: 'top',
          //   formatter: Math.round,
          //   font: {
          //     weight: 'bold'
          //   }
          // },
          title: {
            display: true,
            text: 'MRR',
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };
  
    return <Bar options={options} data={data} />;
  }

export default NetNewMRR;
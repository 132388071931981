const ToolTip = ({
    children,
    value,
    arrowClass='',
    color = "black",
    backgroundColor = "#DDDDDD",
    width="105px",
    ...props
  }) => {
    let bgColor = {
      background: backgroundColor,
      color: color, 
    };
    return (
        <div className="tooltip-container">
        {children}
        {/** tooltip */}
        <div className="tooltip-content">
          <div className="tooltip-inner" >
            <div
              className="tooltip-box"
              style={{...bgColor, width:width}}>
              {value}
            </div>
            <div
              className={`tooltip-arrow ${arrowClass}`}
              style={bgColor}></div>
          </div>
        </div>
      </div>
    );
  };
  
  export default ToolTip;
  
import React, { useState } from "react";
import Input from "components/input/input";
import toast from "react-hot-toast";
import Button from "components/button/button";
import LoadingIndicator from "assets/svg/loading-indicator";
import { LogoWhite } from "constants/images";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ForgotPassword = () => {
  const { http } = global.services;
  const [state, setState] = useState({
    email: "",
    loading: false,
    validationErrors: null,
    success: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState((prev) => ({ ...prev, loading: true }));

    try {
      await http.post("auth/forgot-password", state);
      setTimeout(() => {
        setState((prev) => ({
          ...prev,
          success: true,
          email: "",
          loading: false,
        }));
      }, 250);
    } catch (error) {
      if (error.status === 422) {
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data,
          loading: false,
        }));
      }
      toast.error("It appears that something went wrong");
      return setState((prev) => ({
        ...prev,
        validationErrors: error.data,
        loading: false,
      }));
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Login - Milestones Page Administrator</title>
      </Helmet>
      <div className="form-page-wrapper">
        <div className="form-logo-container">
          <img
            src={LogoWhite}
            alt="component-brand-logo"
            className="form-logo"
          />
        </div>

        <div className="form-container">
          <h2 className="form-column-title-req">Request Password Reset</h2>
          {state.success ? (
            <p className="form-column-subtitle text-green">
              Please check your inbox for the password reset link.
            </p>
          ) : (
            <p className="form-column-subtitle">
              Enter your email on the form below and we'll send you a password
              reset link.
            </p>
          )}

          <form
            className="form-column-container"
            id="forgot-password-component"
            onSubmit={handleSubmit}
          >
            <Input
              inputContainerClass="form-input-container"
              value={state.email}
              defaultValue={state.email}
              autoFocus={true}
              name="email"
              outsideLabel="E-mail Address"
              outsideLabelClass="text-sm font-bold text-darkerGray"
              noLabelPaddingY="1rem"
              type="text"
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.email}
            />

            <Button
              buttonName="Submit"
              disabled={state.loading}
              buttonTextClass="text-sm text-white font-semibold"
              buttonRadius="6px"
              buttonClass="relative bg-primary p-5"
              buttonWidth="100%"
              type="submit"
              icon={
                <div className="absolute right-3 bottom-0 top-0 flex items-center">
                  {state.loading && (
                    <LoadingIndicator className="text-white w-5 h-5" />
                  )}
                </div>
              }
            />
          </form>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default ForgotPassword;

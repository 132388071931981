import React, { createContext, useCallback, useState } from "react";

export const Context = createContext();

const notificationDefaultState = {
  type: "push",
  title: "",
  message: "",
  scheduled: false,
  date: "",
  time: "",
  image: undefined,
  file: undefined,
  buttons: [
    { label: "", url: "" },
    { label: "", url: "" },
  ],
};

export const ContextProvider = ({ initials, children }) => {
  const [alerts, setAlerts] = useState([]);
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [notification, defaultSetNotification] = useState(
    notificationDefaultState
  );

  const setNotification = useCallback(
    (payload) => defaultSetNotification(payload ?? notificationDefaultState),
    [defaultSetNotification]
  );

  return (
    <Context.Provider
      value={{
        alerts,
        setAlerts,
        mobileSidebar,
        setMobileSidebar,
        notification,
        setNotification,
        showPanel,
        setShowPanel,
        selectedUser,
        setSelectedUser,
      }}
    >
      {children}
    </Context.Provider>
  );
};

import React, { useEffect, useState, useLayoutEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux"; 
import Select from "components/select/select";
import { urlSerializer } from "utils/utils";
import InputField from "components/input/input"; 
import moment from "moment";
import LineGraph from "./line-graph";
 

const UserActive = ()=>{
    const months = moment.months();
    const { http } = global.services;

    const [loading, setLoading] = useState(false);
    const [dateSelected, setDateSelected] = useState(null);
    const [state, stateUse] = useState({
        records:{}
    });

    const getProfileViews = async () => {
        setLoading(true);
    
        try {
          const url = urlSerializer("reports/active-users", { 
            date: dateSelected
          });
          const { data } = await http.get(url);
          console.log(data);
          stateUse(data);
        //   const profileViews = {
        //     results: data.results,
        //     today: data.today,
        //     past30: data.past_30,
        //     total: data.total,
        //   };
        //   dispatch({
        //     type: SET_PROFILE_VIEWS,
        //     payload: profileViews,
        //   });
        } catch (err) {
          console.log("getProfileViews", err);
        } finally {
          setLoading(false);
        }
      };

      useEffect(()=>{
        getProfileViews();
      },[dateSelected])

      const handleInputChange = (e) => {
        const {value } = e.target;
        console.log(value)
        setDateSelected(value);
      }; 
    

    return (
        <>
        <div className="w-full bg-white p-2 rounded-md">
            {/* headers */}
            <div className="page-view-heading w-full 2sm:flex 2sm:justify-between 2sm:items-center space">
              <div className="w-full flex flex-row gap-x-2 items-center">
                <h1 className="font-bold text-lightBlack">
                  User Active
                </h1> 
              </div>

              <div className="w-full mt-4 3md:w-6/20"> 
                <InputField
                inputContainerClass="form-input-container"
                value={dateSelected || ""}
                name="date"
                outsideLabel="Select Date"
                placeholder="Date"
                outsideLabelClass="text-sm font-semibold text-lightBlack"
                noLabelPaddingY="0.8rem"
                type="date"
                onChange={handleInputChange}  
                isDisabled={loading}
              />
              </div>
            </div>
            <div className="page-view-graph mt-7.5 w-full">
              { Object.keys(state?.records).length > 0 &&
                <LineGraph
                labels={Object.keys(state?.records)}
                // labels={getDays(dateSelected.year, dateSelected.month)}
                labels2={state?.records}
                serverData={state?.records || []} 
              />
              }
            </div>
            </div>
        </>
    );
    
}
export default UserActive;
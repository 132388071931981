import "./App.css";
import "./custom.css";
import "react-quill/dist/quill.snow.css";
import { ModalProvider } from "react-modal-hook";
import RouterComponent from "router/index";
import NotificationPopupDock from "components/global/notification/popup/notification-popup-dock";

function App() {
  return (
    <div className="App">
      <ModalProvider>
        <RouterComponent />
      </ModalProvider>
      <NotificationPopupDock />
    </div>
  );
}

export default App;

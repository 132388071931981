import React from "react";
import Input from "components/input/input";
import Button from "components/button/button";
// import FilterIconSVG from "assets/svg/filter-icon";
import SearchIconSVG from "assets/svg/search-icon";

const noAddButtonClass =
  "flex space-x-4 w-full 1sm:w-8/12 md:w-6/12 2md:w-4/12";
const withAddButtonClass =
  "space-y-4 mt-3 w-full 1sm:mt-0 1sm:flex 1sm:space-x-4 1sm:space-y-0 1sm:w-11/12 md:w-9/12 2md:w-6/12";

export default function ListHeader({
  title = "List",
  countValue = 3,
  setFilterValue = () => { },
  filterValue = "",
  buttonName = "+ Add Post",
  showAddButton = false,
  onClickButton = () => { },
  onSearch = () => {},
  filter = true
}) {
  return (
    <div className="w-full px-5 py-4 1sm:flex items-center justify-between">
      <p className="text-xl font-medium text-lightGray">
        {title} <span className="opacity-50">({countValue})</span>
      </p>
      <div className={showAddButton ? withAddButtonClass : noAddButtonClass}>
        {/* {
          filter && 
            <Button
            buttonName="Filter"
            buttonTextClass="flex items-center text-base text-lightBlack font-semibold"
            buttonRadius="5px"
            buttonClass="relative bg-white py-3 pl-3 pr-4 border border-borderGray"
            iconPosition="left"
            icon={<FilterIconSVG className="relative mr-1" />}
          />
        } */}
        
        <form className="w-full" onSubmit={onSearch}>
          <Input
            inputContainerClass="w-full relative"
            value={filterValue}
            name="search"
            iconPosition="left"
            inputIcon={<SearchIconSVG fill="#000" />}
            placeholder="Search Here"
            noLabelPaddingY="0.8rem"
            type="text"
            onChange={(e) => setFilterValue(e.target?.value)}
          />
        </form>
        {showAddButton && (
          <Button
            buttonName={buttonName}
            buttonTextClass="text-base text-white font-semibold"
            buttonRadius="6px"
            buttonWidth="40%"
            buttonClass="relative bg-primary py-3"
            onClick={onClickButton}
          />
        )}
      </div>
    </div>
  );
}

import Card from "components/card/card";

export default function ListContainer({ children, header }) {
  return (
    <Card noPadding={true}>
      {header && header}
      {children}
    </Card>
  );
}

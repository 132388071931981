import Modal from "components/global/modal"
import ModalHeader from "components/global/modal-header";
import Button from "components/button/button"; 
import toast from "react-hot-toast";
import { useState } from "react";
import LoadingIndicator from "assets/svg/loading-indicator";
const ModalReport = ({details,hide=()=>{}, ...props}) =>{ 
    const {instruction, account_report} = details;
    const { http } = global.services;
    const [isLoading,setIsLoading] = useState(false);

    const handleUpdate = async()=>{
        const payload = {...instruction, sendTo:account_report.email}
        try{
          setIsLoading(true) 
          const {data } = await http.put(`reports/update/${details.id}`,payload);
          toast.success(data.message);
          details.status = instruction.title;
          hide({success:true, data:data});
        }catch(err){
          toast.error(`It appears that something went wrong`); 
        }finally{
          hide();
        }

    }

    const Footer = () => {
        return (
          <div className="relative flex flex-row items-center justify-end space-x-2">
            <Button
              key="cancel-btn-category"
              buttonName="Cancel"
              buttonClass="bg-thBg py-3 px-9"
              buttonTextClass="text-black text-xs font-bold"
              onClick={() => hide()}
            />
            <Button
              key="delete-btn"
              buttonName={instruction.status}
              buttonClass="bg-secondary py-3 px-8 "
              buttonTextClass="text-white text-xs font-bold capitalize"
              onClick={handleUpdate}
              loadingIcon={
                isLoading ? (
                <LoadingIndicator className="text-white w-4 h-4 ml-2" />
                ) : null
            }
            />
          </div>
        );
      };

    return (
        <Modal
        footer={<Footer />}
        width="w-16/20 md:w-13/20 2md:w-9/20 3md:w-6/20 1xl:w-5/20"
        contentClassName="rounded-lg overflow-auto custom-scroll"
        wrapperClassName="overflow-visible"
        footerClass="border-t border-mutedGray"
        >
            <ModalHeader
            title={instruction.title}
            hide={hide}
            />

            <div className="px-4 py-4">
                <p className="text-lightBlack">
                    Are you sure you want to {instruction.desc}
                    <strong> {`${account_report.first_name} ${account_report.last_name}`} ({account_report.email}) </strong>
                </p>
            </div>
        </Modal>
    )
}

export default ModalReport;
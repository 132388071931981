import React, { useState, useEffect } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import _ from "lodash";
import ModalHeader from "components/global/modal-header";
import Input from "components/input/input";
import Select from "components/select/select";
import TextArea from "components/textarea/textarea";
import Toggle from "components/toggle/toggle";
import TrashIconSVG from "assets/svg/trash-icon";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

export default function CategoryAddModal({ hide, userData, ...props }) {
  const { http } = global.services;

  const [state, setState] = useState({
    show: true,
    loading: false,
    is_show_on_home: true,
    validationErrors: null,
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleSubmit = async () => {
    let error = "";
    try {
      let payload = _.clone(state);
      delete payload.loading;
      delete payload.validationErrors;

      const { data } = props.id
        ? await http.put(`categories/${props.id}`, payload)
        : await http.post("categories/create", payload);

      if (data) {
        hide("add");
      }
    } catch (err) {
      const { data, status } = err;
      if (status === 422) {
        error = data.errors;
      }
    }
    setErrors(error || {});
  };

  const Footer = () => {
    return (
      <div className="w-full 1sm:flex 1sm:justify-between">
        <div className="w-full grid mb-3 1sm:mb-0 1sm:flex">
          {props?.id && (
            <Button
              key="delete-btn"
              buttonName="Delete"
              buttonClass="bg-danger py-3 px-6"
              buttonTextClass="text-white text-sm font-bold flex items-center justify-center"
              onClick={() => hide("delete")}
              iconPosition="left"
              icon={<TrashIconSVG className="mr-2" fill="white" />}
            />
          )}
        </div>
        <div className="relative grid gap-y-3 1sm:gap-y-0 1sm:flex 1sm:flex-row 1sm:items-center 1sm:justify-end 1sm:space-x-2">
          <Button
            buttonName="Cancel"
            buttonClass="bg-thBg py-3 px-9"
            buttonTextClass="text-black text-sm font-bold"
            onClick={() => hide()}
          />
          <Button
            key="save-btn-category"
            buttonName="Save"
            buttonClass="bg-primary py-3 px-12"
            buttonTextClass="text-white text-sm font-bold"
            onClick={handleSubmit}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (props && props.id) {
      setState((prev) => ({
        ...prev,
        id: props.id,
        name: props.name,
        description: props.description,
        group: props.group,
        english_version: props.english_version,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);

  return (
    <Modal
      footer={<Footer />}
      width="w-19/20 md:w-16/20 2md:w-12/20 3md:w-8/20 1xl:w-6/20"
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-mutedGray"
    >
      <div className="w-full">
        {/* header */}
        <ModalHeader
          title={props?.id ? "Edit Category" : "Add Category"}
          hide={hide}
        />
        {/* content */}
        <div className="pl-10 pr-7 py-3.5">
          <div className="w-full">
            <Input
              inputContainerClass="form-input-container"
              value={state?.name || ""}
              name="name"
              outsideLabel="Category Title"
              placeholder="Enter Title"
              outsideLabelClass="text-sm font-semibold text-lightBlack"
              noLabelPaddingY="0.8rem"
              type="text"
              onChange={handleInputChange}
              errorMessage={errors?.name}
              errorMessagePosition="bottom"
            />
            <Input
              inputContainerClass="form-input-container"
              value={state?.english_version || ""}
              name="english_version"
              outsideLabel="American English "
              placeholder="American English"
              outsideLabelClass="text-sm font-semibold text-lightBlack"
              noLabelPaddingY="0.8rem"
              type="text"
              onChange={handleInputChange}
              errorMessage={errors?.english_version}
              errorMessagePosition="bottom"
            />
            {/* <TextArea
              label="Description"
              placeholder="Enter Description"
              name="description"
              type="text"
              value={state?.description || ""}
              onChange={handleInputChange}
              errorMessage={errors?.description}
            />
            <Select
              label="Group Category"
              options={options}
              placeholder="Select Category"
              name="group"
              value={options.filter((opt) => opt.value === state.group)}
              onChange={(e) =>
                handleInputChange({ target: { name: "group", value: e.value } })
              }
              errorMessage={errors?.group}
            /> */}
          </div>
        </div>
        {/* <div className="w-full pl-10 pr-7 py-3.5 bg-modalGray ">
          <div className="w-full flex justify-between items-center">
            <p className="text-lightBlack font-medium">
              Show this on Homepage?
            </p>
            <div className="flex items-center space-x-3.5">
              <p className="text-lightBlack font-semibold">
                {state?.is_show_on_home ? "Yes" : "No"}
              </p>
              <Toggle
                checked={state?.is_show_on_home}
                name="is_show_on_home"
                onChange={(e) =>
                  handleInputChange({
                    target: {
                      name: "is_show_on_home",
                      value: e?.target?.checked,
                    },
                  })
                }
              />
            </div>
          </div>
        </div> */}
        {/* end content */}
      </div>
    </Modal>
  );
}

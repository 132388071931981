
import Modal from "components/global/modal";
import ModalHeader from "components/global/modal-header"; 
import { FaEllipsisH } from "react-icons/fa";
import { IoEllipse } from "react-icons/io5";
import { BsGlobe2, BsExclamationDiamondFill } from "react-icons/bs";
import { formatDate } from "utils/utils";
import _ from "lodash"; 
import Popup from "reactjs-popup";
import { useRef,useEffect,useState } from "react";
import Button from "components/button/button";

const Milestone = ({milestone,hide = ()=>{},...props})=>{ 
    const featuredDate = formatDate(milestone);
    const listenRef = useRef();
    const [showAudioPlayer, setShowAudioPlayer] = useState();

    useEffect(()=>{
        window.addEventListener(
            'scroll',
            ()=>{
               if(listenRef.current){
                listenRef.current.close();
               } 
            },
            true
        );
        return ()=> window.removeEventListener('scroll',()=>{});
    },[])

    const handleMedia = async (media) => {
        // let url = "";
        // let fileExt = "";
    
        // if (
        //   media.sub_type.name === "Multiple Images" ||
        //   media.sub_type.name === "Single Image"
        // ) {
        //   onViewImage(title, media, media["media"]);
        // } else {
        //   if (media.url) {
        //     url = media.url;
        //   } else {
        //     url = media["media"][0]["media"]["url"];
        //     fileExt = media["media"][0]["media"]["file_extension"];
        //   }
    
        //   if (await isImgUrl(url)) {
        //     onViewImage(title, media, media["media"]);
        //   } else if (ReactPlayer.canPlay(url)) {
        //     onWatchMedia(title, url);
        //   } else if (fileExt === "pdf") {
        //     handleViewPdf(title, url);
        //   } else {
        //     if (url.includes("https://www.tiktok.com/")) {
        //       onWatchMedia(title, url, true);
        //     } else {
        //       window.open(url, "_blank");
        //     }
        //   }
        // }
      };

    return(
    <Modal 
        width="w-full md:w-16/20 lg:w-14/20 xl:w-15/20"
        contentClassName="rounded-lg overflow-auto custom-scroll"
        wrapperClassName="overflow-visible"
        footerClass="border-t border-mutedGray"
    >
        <ModalHeader
          title="Milestone Report"
          hide={hide}
        /> 
        <div className={`pl-10 pr-7 py-3.5`}>
            <div className="w-full 2md:flex space-y-0.5 2md:space-x-0.5 2md:space-y-0 gap-4">
                {
                    milestone?.thumbnail && (
                        <div className="relative bg-white flex items-center"> 
                        <img 
                            className="h-auto w-[400px] object-contain max-h-96"
                            src={milestone?.thumbnail?.url}/> 
                        </div>
                    )
                }
            <div className="relative w-full bg-white"> 
            {/* content */}
            <div className="w-full 1sm:flex 1sm:justify-between">
                <div className="w-full">
                {/* title */}
                <div className="flex items-center space-x-5 flex-wrap">
                    <p className="text-3xl text-lightBlack font-medium tracking-tighter">
                    {milestone.title}
                    </p>
                </div>
                {/* category */}
                <div className="flex items-center space-x-5 flex-wrap mb-1">
                    <p className="px-4.5 py-1.5 bg-lightGreen text-white text-xs uppercase font-semibold rounded-md">
                    {milestone?.category?.name}
                    </p>
                </div>
                {/* details */}
                <div className="w-full">
                    <div className="w-full text-darkerGray font-medium leading-6 flex items-center space-x-3 flex-wrap">
                    <p>
                        {featuredDate}
                        {milestone.dateNote && (
                        <span className="ml-1">{`(${milestone.dateNote})`}</span>
                        )}
                    </p>
                    <p>
                        <IoEllipse className="w-1 h-1" />
                    </p>
                    <p className="flex items-center capitalize">
                        {milestone.privacy === "public" ? (
                        <BsGlobe2 className="mr-1.5" />
                        ) : (
                        <BsExclamationDiamondFill className="mr-1.5" />
                        )}
                        {milestone.privacy}
                    </p>
                    {milestone.people && (
                        <>
                        <p className="hidden md:block">
                            <IoEllipse className="w-1 h-1" />
                        </p>
                        <p className="hidden md:flex items-center">
                            <FaEllipsisH className="mr-1.5" />
                            {milestone.people}
                        </p>
                        </>
                    )}
                    </div>
                </div>
                {/* Author Name and description */}
                <div className="w-full mt-4">
                    <p className="text-base font-bold text-primary leading-6">
                    {milestone.positions.map((p, i) => (
                        <span key={i}>
                        {p.position.name}
                        {p.description && ` - ${p.description} `}
                        {i < milestone.positions.length - 1 && ", "}
                        </span>
                    ))}
                    {/* <span className="pl-1">{platform && `(${platform})`}</span> */}
                    </p>
                    <p
                    className="mt-2.5 text-base leading-7.5 tracking-tightest truncate-multiline"
                    dangerouslySetInnerHTML={{ __html: milestone.description || '' }}></p>
                </div>
                            {/* buttons */}
                            {!_.isEmpty(milestone.media_type) && (
                                <div className="w-full mt-8 2md:mt-11">
                                <div className="w-full grid 3sm:grid-cols-3 lg:grid-cols-6 gap-3">
                                    {milestone?.media_type.map((btn, index) => {
                                    if (btn.type.name === "Listen") {
                                        return (
                                        <Popup
                                            ref={listenRef}
                                            key={index}
                                            position="bottom left"
                                            open={showAudioPlayer}
                                            onClose={() => setShowAudioPlayer(false)}
                                            arrow={false}
                                            contentStyle={{ marginTop: 20 }}
                                            trigger={(open) => {
                                            return (
                                                <button
                                                className={`rounded-md py-2 px-10 2md:px-4 lg:py-5 lg:px-4 xl:px-0 btn-hover-white-primary border-2 border-gray text-xs 2md:text-sm text-lightBlack font-semibold ${showAudioPlayer ? "bg-darkPrimary" : ""
                                                    }`}
                                                onClick={() =>
                                                    setShowAudioPlayer((prev) => !prev)
                                                }>
                                                Listen
                                                </button>
                                            );
                                            }}>
                                            {/* <AudioPlayer media={btn} /> */}
                                        </Popup>
                                        );
                                    }

                                    return (
                                        <Button
                                        key={index}
                                        buttonName={btn.type.name}
                                        buttonTextClass={`text-xs 2md:text-sm text-lightBlack font-semibold`}
                                        buttonRadius="6px"
                                        buttonClass={`relative py-2 px-10 2md:px-4 xl:px-0 btn-hover-white-primary bg-white border-2 border-gray`}
                                        onClick={() => handleMedia(btn)}
                                        />
                                    );
                                    })}
                                </div>
                                </div>
                            )}
                    </div> 
                        </div>
            </div>
        </div>
            
        </div>   
    </Modal>
    )
}

export default Milestone;
import { SET_GROUP, GET_GROUP, REFRESH_GROUP } from "./types";
const initialState = {
  initialize: true,
  refresh: false,
  isRequesting: false,
  loadMore: true,
  group: [],
  limit: 10,
  page: 1,
  pages: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REFRESH_GROUP:
      return {
        ...state,
        limit: 10,
        page: 1,
        initialize: true,
        refresh: true,
        isRequesting: false,
        loadMore: true,
        group: [],
      };

    case GET_GROUP:
      return {
        ...state,
        refresh: false,
        isRequesting: true,
      };

    case SET_GROUP:
      const tempPages = [];

      if(payload.lastPage > 0 && state.lastPage != payload.lastPage) {
        for(let i = 1; i <= payload.lastPage; i++) {
          tempPages.push(i);
        }
      } 

      return {
        ...state,
        isRequesting: false,
        initialize: false,
        page: payload.page,
        loadMore: payload.loadMore,
        group: payload.data,
        pages: tempPages.length > 0 ? tempPages : state.pages
      };

    default:
      return state;
  }
};

export default reducer;

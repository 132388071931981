import {
	SET_USER_DATA
} from './types'

const initialState = {
	userData: null,
};

const reducer = (state = initialState, {type, payload}) => {

	switch (type) {

		case SET_USER_DATA:
			return {
				...state,
				userData: payload
			}

		default:
			return state;

	}

}

export default reducer
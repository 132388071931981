import React, { useEffect, useState } from "react";
import {
  useLocation,
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import jwt from "jsonwebtoken";
import RouteList from "./list";
import userActions from "redux/modules/user/actions";
import toast, { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import { PusherProvider } from "context/pusher";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => window.scrollTo(0, 0), [pathname]);
  return null;
}

const RouterComponent = ({ user = null, setUserData }) => {
  const { localstorage, http } = global.services;
  const [state, setState] = useState({
    renderContent: false,
  });

  useEffect(() => {
    const getUserData = async () => {
      const userData = localstorage.get("user_data");

      if (userData.value) {
        try {
          await http.get("auth/user-profile");
          setUserData(JSON.parse(userData.value));
        } catch (error) {
          console.error(error);
          localstorage.remove("user_data");
          setUserData(null);
          toast.error("It appears that something went wrong");
          return (window.location.href = `/login`);
        }
      }

      setState((prevState) => ({ ...prevState, renderContent: true }));
    };

    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localstorage, setUserData]);

  const renderRoutes = () => {
    const routes = RouteList.map((route, routeIndex) => {
      return (
        <Route
          exact
          key={`route-${routeIndex}`}
          path={route.path}
          render={() => {
            if (route.authRequired) {
              if (!user) return <Redirect to={{ pathname: "/login" }} />;

              const decoded = jwt.decode(user?.access_token);
              // console.log("token", user?.access_token);
              if (decoded) {
                const todayDate = Date.now();
                const refreshTTLDate = user?.refresh_ttl_expires * 1000;

                if (refreshTTLDate > todayDate) return route.component();
                else return <Redirect to={{ pathname: "/login" }} />;
              }
            }

            return route.component();
          }}
        />
      );
    });
    return routes;
    // return <PusherProvider>{routes}</PusherProvider>;
  };

  return (
    state.renderContent && (
      <React.Fragment>
        <Toaster toastOptions={{ duration: 3000 }} position="top-right" />
        <Router>
          <ScrollToTop />
          <PusherProvider>
            <Switch>{renderRoutes()}</Switch>
          </PusherProvider>
        </Router>
      </React.Fragment>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouterComponent);

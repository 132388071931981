/** Resource: https://jakzaizzat.com/how-to-build-custom-checkbox-in-tailwindcss/ */
import _ from "lodash";
import React from "react";

const FileUploadComponent = (
  {
    id = "media-upload",
    label = "Choose Image",
    title = "Select an image file on your computer (500 x 500px)",
    allowedFiles = null,
    uploading = false,
    image_url = null,
    wrapperClass = "mt-5",
    leftContainerClass = "w-25 flex justify-center items-center",
    leftContentClass = "w-25 h-full rounded-lg",
    rightContainerClass = "px-6 py-1",
    rightContentClass = "my-2",
    titleTextClass = "text-lightblack text-sm mb-5",
    className = "bg-white rounded-md border-2 border-primary py-3 px-4 text-sm text-primary font-semibold",
    icon = null,
    iconLeft = null,
    removable = false,
    onRemoveImage = () => {},
    ...props
  },
  ref
) => {
  return (
    <div className={wrapperClass}>
      <div className="flex flex-row">
        {/* ---- Left Section ---- */}
        <div className={leftContainerClass} style={{ minHeight: "104px" }}>
          {uploading ? (
            // Upload Loader
            <div
              className={`${leftContentClass} flex justify-center items-center`}
            >
              loading...
            </div>
          ) : // Image Display
          image_url ? (
            <div
              className={`${leftContentClass} relative flex justify-center items-center`}
            >
              <img
                src={image_url}
                className="w-full h-full object-contain"
                alt="upload-images"
              />
            </div>
          ) : (
            <div
              className={`${leftContentClass} bg-default flex justify-center items-center`}
            >
              {iconLeft}
            </div>
          )}
        </div>
        {/* ---- Right Section ---- */}
        <div className={rightContainerClass}>
          {/* Title */}
          {title && <p className={titleTextClass}>{title}</p>}
          {/* Content */}
          <div className={"cursor-pointer " + rightContentClass}>
            <label
              htmlFor={id}
              // style={{ width: width }}
              className={"cursor-pointer " + className}
            >
              {icon && icon}
              {label}
            </label>
            {/* {removable && ( */}

            <input ref={ref} type="file" id={id} name={id} {...props} />
            {!_.isEmpty(image_url) && removable && (
              <button
                className="2sm:ml-3 mt-4 2sm:mt-0 bg-thBg py-3 px-4 text-sm font-semibold text-lightBlack rounded-md"
                onClick={onRemoveImage}
              >
                Remove Image
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const FileUpload = React.forwardRef(FileUploadComponent);

export default FileUpload;

import React from "react";
import { connect } from "react-redux";
import Layout from "layouts/layout";
import Statistics from "components/dashboard/statistics";
import {
  BookIcon,
  ClockIcon,
  CupIcon,
  GraphIcon,
  GraphLowIcon,
  UserIcon,
} from "assets/svg/dashboard/icons";
import { Avatar } from "constants/images";
import EarningCharts from "components/dashboard/earnings-chart";
import DoughnutCharts from "components/dashboard/doughnut-chart";
import UserActive from "./components/user-active";
import UserSubscribe from "./components/user-subscribe";
import MrrChurn from "./components/mrr-churn";
import UserChurn from "./components/user-churn";
import NetNewMRR from "./components/net-new-mrr";
import LifeTimeValue from "./components/life-time-value";

const statisticsData = [
  {
    label: "Lorem Ipsum Dolor",
    value: 62,
    icon: <UserIcon />,
  },
  {
    label: "Lorem Ipsum Dolor",
    value: 6.8,
    icon: <GraphIcon />,
  },
  {
    label: "Lorem Ipsum Dolor",
    value: "9",
    percent: "14%",
    icon: <GraphLowIcon />,
  },
  {
    label: "Lorem Ipsum Dolor",
    value: "83%",
    icon: <BookIcon />,
  },
];

const subStatistics = [
  {
    label: "Lorem Ipsum Dolor",
    value: 62,
    icon: <CupIcon />,
  },
  {
    label: "Lorem Ipsum Dolor",
    value: 6.8,
    icon: <ClockIcon />,
  },
];

const listUsers = [
  {
    name: "Annette Watson",
    value: 9.3,
  },
  {
    name: "Calvin Steward",
    value: 8.9,
  },
  {
    name: "Ralph Richards",
    value: 8.7,
  },
  {
    name: "Bernard Murphy",
    value: 8.2,
  },
  {
    name: "Arlene Robertson",
    value: 8.2,
  },
  {
    name: "Jane Lane",
    value: 8.1,
  },
  {
    name: "Pat Mckinney",
    value: 7.9,
  },
];

const pieChart = [
  {
    name: "Lorem Ipsum Dolor",
    value: 20,
    percent: "32%",
    color: "voilet",
  },
  {
    name: "Dolor Ipsum Lorem",
    value: 20,
    percent: "32%",
    color: "darkYellow",
  },
  {
    name: "Ipsum Dolor Lorem",
    value: 15,
    percent: "24%",
    color: "pink",
  },
  {
    name: "Lorem Ipsum Dolor",
    value: 7,
    percent: "12%",
    color: "darkGreen",
  },
];

// charts
const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const datasets = [
  {
    label: "Cat 01",
    data: [19, 15, 24, 8, 12, 10, 18, 19, 13, 23, 12],
    backgroundColor: "#0C6ED1",
    borderRadius: 999,
  },
  {
    label: "Cat 02",
    data: [16, 8, 14, 7, 5, 16, 13, 12, 8, 15, 24],
    backgroundColor: "#FF7A00",
    borderRadius: 999,
  },
];

const doughnutLabels = [
  "Lorem Ipsum Dolor",
  "Dolor Ipsum Lorem",
  "Ipsum Dolor Lorem",
  "Lorem Ipsum Dolor",
];

const doughnutDatasets = {
  data: [20, 20, 15, 7],
  backgroundColor: ["#6F52ED", "#FFB800", "#FF4C61", "#33D69F"],
  borderColor: ["#6F52ED", "#FFB800", "#FF4C61", "#33D69F"],
  borderWidth: 1,
};

//end charts

const Dashboard = ({ user }) => {
  return (
    <Layout title="Dashboard" routeName="dashboard">
      <div className="main-page-wrapper">
        <div className="flex mb-4 px-2">
          <LifeTimeValue/>
        </div> 
        <div className="flex mb-4 px-2">
          <UserSubscribe/>
        </div>
        <div className="flex mb-4 px-2">
          <NetNewMRR/>
        </div>
        <div className="flex mb-4 px-2">
          <MrrChurn/>
        </div>
        <div className="flex mb-4 px-2">
          <UserChurn/>
        </div>
        <div className="flex mb-10 px-2">
          <UserActive/>
        </div>
        {/* stats */}
        <div className="w-full px-px my-px overflow-hidden">
          <div className="flex flex-col flex-1 w-full">
            <Statistics items={statisticsData} />
          </div>
        </div>
        {/* end stats */}
        {/* second row */}
        <div className="mt-10 w-full px-px my-px overflow-hidden">
          <div className="flex flex-col flex-1 w-full">
            <div className="statistics-container">
              {/* col 1 */}
              <div className="w-full px-4 my-2 2md:my-0 overflow-hidden 2md:w-2/4">
                {/* main content 1 */}
                <div className="w-full bg-white rounded-xl">
                  {/* header */}
                  <div className="w-full px-7 py-6 border-b border-bGray flex justify-between">
                    <p className="font-bold text-lightBlack">
                      Lorem Ipsum Dolor
                    </p>
                    <div className="flex items-center space-x-4">
                      <p className="relative flex items-center">
                        <span className="absolute left-0 w-2 h-2 bg-primary rounded-full" />
                        <span className="pl-4">Cat 01</span>
                      </p>
                      <p className="relative flex items-center">
                        <span className="absolute left-0 w-2 h-2 bg-darkOrange rounded-full" />
                        <span className="pl-4">Cat 02</span>
                      </p>
                    </div>
                  </div>
                  {/* content */}
                  <div className="w-full px-7 py-6">
                    <EarningCharts labels={labels} datasets={datasets} />
                  </div>
                </div>
                {/* sub content */}
                <div className="mt-10 w-full px-px my-px overflow-hidden">
                  <div className="flex flex-col flex-1 w-full">
                    <Statistics items={subStatistics} number={2} sub={true} />
                  </div>
                </div>
                {/* end: sub content */}
              </div>
              {/* col 2 */}
              <div className="w-full px-4 my-2 2md:my-0 overflow-y-auto 2md:w-1/4">
                {/* main content 2 */}
                <div className="w-full bg-white h-full rounded-xl">
                  {/* header */}
                  <div className="w-full px-7 py-6 border-b border-bGray">
                    <p className="font-bold text-lightBlack">
                      Lorem Ipsum Dolor
                    </p>
                  </div>
                  {/* content */}
                  <div className="w-full px-7 py-6">
                    <ul className="w-full overflow-y-auto">
                      {listUsers.map((obj, index) => (
                        <li
                          key={index}
                          className="flex justify-between items-center py-2.5"
                        >
                          <div className="flex items-center space-x-3">
                            <img src={Avatar} alt="milestones-avatar" />
                            <p className="text-darkerGray">{obj.name}</p>
                          </div>
                          <p className="font-bold text-lightBlack">
                            {obj.value}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* col 3 */}
              <div className="w-full px-4 my-2 2md:my-0 overflow-hidden 2md:w-1/4">
                {/* main content 3 */}
                <div className="w-full bg-white h-full rounded-xl">
                  {/* header */}
                  <div className="w-full px-7 py-6 border-b border-bGray">
                    <p className="font-bold text-lightBlack">
                      Lorem Ipsum Dolor
                    </p>
                  </div>
                  {/* content */}
                  <div className="w-full px-7 py-6">
                    <DoughnutCharts
                      labels={doughnutLabels}
                      datasets={doughnutDatasets}
                    />
                    <ul className="mt-10 w-full overflow-y-auto">
                      {pieChart.map((obj, index) => (
                        <li
                          key={index}
                          className="flex justify-between items-center py-2"
                        >
                          <p className="relative flex items-center">
                            <span
                              className={`absolute left-0 w-2 h-2 bg-${obj.color} rounded-full`}
                            />
                            <span className="pl-4 text-darkerGray">
                              {obj.name}
                            </span>
                          </p>
                          <p className="font-bold text-lightBlack">
                            {obj.value}
                            {obj.percent && (
                              <small className="pl-1 opacity-60">{`(${obj.percent})`}</small>
                            )}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* second row */}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};
export default connect(mapStateToProps)(Dashboard);

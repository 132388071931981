import React, { useEffect, useState, useLayoutEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux"; 
import Select from "components/select/select";
import { urlSerializer } from "utils/utils"; 
import ChartDataLabels from "chartjs-plugin-datalabels";  
import { Line } from "react-chartjs-2"; 
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"; 
import { Bar } from "react-chartjs-2"; 

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    // ChartDataLabels
  );

const LifeTimeValue = ({term ='month'})=>{ 
    const { http } = global.services; 
    const [loading, setLoading] = useState(false);
    const [dateSelected, setDateSelected] = useState({
        year:new Date().getFullYear()
    });
    const [state, stateUse] = useState({ 
    });

    const getProfileViews = async () => {
        setLoading(true); 
        try {
          const url = urlSerializer("reports/life-time-value", { 
            term: term
          });
          const { data } = await http.get(url);
          console.log(data);
          stateUse(data);
        } catch (err) {
          console.log("getProfileViews", err);
        } finally {
          setLoading(false);
        }
    };
 

    useEffect(()=>{
        getProfileViews();
      },[dateSelected])
 
    

    return (
        <>
        <div className="w-full bg-white p-2 rounded-md">
            {/* headers */}
            <div className="page-view-heading w-full 2sm:flex 2sm:justify-between 2sm:items-center space">
              <div className="w-full flex flex-row gap-x-2 items-center">
                <h1 className="font-bold text-lightBlack">
                Monthly & Yearly Plan
                </h1> 
              </div>

              {/* <div className="w-full mt-4 3md:w-6/20">  
              <Select
                  options={yearOption()}
                  placeholder="Select Year"
                  onChange={(e) => handleInputChange({ target: { name: "year", value: e.value } })}
                  height={40}
                  backgroundColor="#F2F2F2"
                  borderColor="#F2F2F2"
                  isDisabled={loading}
                  value={yearOption().filter((opt) => opt.value === dateSelected.year)}
                />
              </div> */}
            </div>
            <div className="page-view-graph mt-7.5 w-full"> 
                <StackGraph
                serverData={state || []}  
              /> 
            </div>
            </div>
        </>
    );
    
}

function StackGraph(serverData = []) { 
  console.log(serverData); 
    const plan  = serverData.serverData || []; 
    // const labels = plan.length > 0  ? plan.map(label => label.term.toUpperCase()) : []; 
    const labels = ['30 Days', '365 Days']; 
    const amount =  plan.length > 0  ? plan.map(label => label?.total_amount) : [];
    const overAllTotal = amount.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const data = {
        labels,
        datasets: [ 
          {
            label: 'Total average',
            data:  amount,
            backgroundColor: 'rgb(53, 162, 235)',
          },
        ],
      };
  
    const options = {
        // indexAxis: 'y',
        plugins: {
        legend: { 
            maxHeight: 10
            },
          title: {
            display: true,
            text: `Life Time Value is $${parseFloat(overAllTotal).toFixed(2)}`,
          },
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: function(value) {
                return `$${parseFloat(value).toFixed(2)}`; 
              },
            font: {
              weight: 'bold'
            }
          }
        },
        elements: {
            bar: {
              borderWidth: 2, 
            }
          },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };
  
    return <Line options={options} data={data}  plugins={[ChartDataLabels]}  />;
  }

export default LifeTimeValue;
import axios from 'axios'
import { API_ENDPOINT } from 'configs/api'
import LocalStorageService from 'services/local-storage'

class HttpService {

	async config (config, endpoint) {
		const rawUserData = LocalStorageService.get('user_data')
		let token = null
		let userData = null

		if (rawUserData.value) {
			userData = JSON.parse(rawUserData.value)
			token = userData.access_token
		}

		if (!endpoint.includes('http://') && !endpoint.includes('https://'))
			config.baseURL = API_ENDPOINT

		if (!config.headers)
			config.headers = {}
		
		if (token && !config.headers.Authorization && !endpoint.includes('http://') && !endpoint.includes('https://'))
			config.headers.Authorization = `Bearer ${token}`

		return config;
	}

	handleErrorResponse (errorData) {
		let throwbackData = errorData
		if (errorData.response) {
			throwbackData = errorData.response
			console.error(throwbackData);
			if (throwbackData.status === 401 && (throwbackData.data.type === 'TokenInvalidException' || throwbackData.data.type === 'TokenBlacklistedException')) {
				let authErrorMessage = throwbackData.data?.message || 'Your session has expired. Please re-login to access your dashboard again.'
				LocalStorageService.remove('user_data')
				LocalStorageService.set('authentication_error', authErrorMessage)
				window.location.href = `${ window.location.origin }/login`
			}
		}

		return throwbackData
	}

	handleResponse (response) {
		const headers = response.headers;
		if(headers['authorization']) {
			const userdata = LocalStorageService.get('user_data')
			const parsedUserData = JSON.parse(userdata.value)
			const newData = {
				...parsedUserData,
				access_token: headers['authorization']
			}
			LocalStorageService.set('user_data', JSON.stringify(newData))
		}

		return response
	}

	get (endpoint, params, config = { headers: {} }) {
		return new Promise(async (resolve, reject) => {
			config.params = params;
			config = await this.config(config, endpoint)
			axios.get(endpoint, config)
				.then(response => {
					resolve(this.handleResponse(response))
				})
				.catch(err => reject(this.handleErrorResponse(err)))
		})
	}

	post (endpoint, params, config = { headers: {} }) {
		return new Promise(async (resolve, reject) => {
			config = await this.config(config, endpoint)
			axios.post(endpoint, params, config)
				.then(response => resolve(this.handleResponse(response)))
				.catch(err => reject(this.handleErrorResponse(err)))
		})
	}

	put (endpoint, params, config = { headers: {} }) {
		return new Promise(async (resolve, reject) => {
			config = await this.config(config, endpoint)
			axios.put(endpoint, params, config)
				.then(response => resolve(this.handleResponse(response)))
				.catch(err => reject(this.handleErrorResponse(err)))
		})
	}

	patch (endpoint, params, config = { headers: {} }) {
		return new Promise(async (resolve, reject) => {
			config = await this.config(config, endpoint)
			axios.patch(endpoint, params, config)
				.then(response => resolve(this.handleResponse(response)))
				.catch(err => reject(this.handleErrorResponse(err)))
		})
	}

	delete (endpoint, params, config = { headers: {} }) {
		return new Promise(async (resolve, reject) => {
			config.params = params;
			config = await this.config(config, endpoint)
			axios.delete(endpoint, config)
				.then(response => resolve(this.handleResponse(response)))
				.catch(err => reject(this.handleErrorResponse(err)))
		})
	}

}

export default new HttpService()
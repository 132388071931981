import React from "react";
import "./styles.css";

export default function Radio({
  label = "",
  active = false,
  ...props // make sure this pops here is for input only
}) {
  console.log(label,
    active,
    props)
  return (
    <div
      className={`w-full ${active ? "bg-radioActive" : "bg-thBg"
        } py-3.5 px-4 rounded-md`}
    >
      <label className="container">
        {label}
        <input type="radio" name="radio" {...props} />
        <span className="checkmark mt-0.5" />
      </label>
    </div>
  );
}

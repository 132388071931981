const PrintIconSVG = ({ className = "" }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.20456 2.72729V6.70457H4.06819C3.13353 6.70457 2.36365 7.47445 2.36365 8.40911V14.0909H5.20456V16.3637H13.1591V14.0909H16V8.40911C16 7.47445 15.2301 6.70457 14.2955 6.70457H13.1591V2.72729H5.20456ZM6.34092 3.86366H12.0227V6.70457H6.34092V3.86366ZM4.06819 7.84093H14.2955C14.6171 7.84093 14.8636 8.08752 14.8636 8.40911V12.9546H13.1591V10.6818H5.20456V12.9546H3.50001V8.40911C3.50001 8.08752 3.7466 7.84093 4.06819 7.84093ZM4.63637 8.40911C4.32387 8.40911 4.06819 8.66479 4.06819 8.97729C4.06819 9.28979 4.32387 9.54548 4.63637 9.54548C4.94887 9.54548 5.20456 9.28979 5.20456 8.97729C5.20456 8.66479 4.94887 8.40911 4.63637 8.40911ZM6.34092 11.8182H12.0227V15.2273H6.34092V11.8182Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PrintIconSVG;

import React, { useEffect, useState, useLayoutEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux"; 
import Select from "components/select/select";
import { urlSerializer } from "utils/utils";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"; 
import { Bar } from "react-chartjs-2"; 

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

const UserSubscribe = ()=>{ 
    const { http } = global.services; 
    const [loading, setLoading] = useState(false);
    const [dateSelected, setDateSelected] = useState({
        year:new Date().getFullYear()
    });
    const [state, stateUse] = useState({
        results:{}
    });

    const getProfileViews = async () => {
        setLoading(true); 
        try {
          const url = urlSerializer("reports/user-subscribe", { 
            year: dateSelected.year
          });
          const { data } = await http.get(url);
          console.log(data);
          stateUse(data);
        //   const profileViews = {
        //     results: data.results,
        //     today: data.today,
        //     past30: data.past_30,
        //     total: data.total,
        //   };
        //   dispatch({
        //     type: SET_PROFILE_VIEWS,
        //     payload: profileViews,
        //   });
        } catch (err) {
          console.log("getProfileViews", err);
        } finally {
          setLoading(false);
        }
    };

    const yearOption = ()=>{
        let year = []
        for(let x = 2020; x <= 2035; x++){
            year.push({
                label:x,
                value:x
            });
        } 
        return year;
    };
    

    useEffect(()=>{
        getProfileViews();
      },[dateSelected])

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDateSelected((prev) => ({
          ...prev,
          [name]: value, 
        }));
      }; 
    

    return (
        <>
        <div className="w-full bg-white p-2 rounded-md">
            {/* headers */}
            <div className="page-view-heading w-full 2sm:flex 2sm:justify-between 2sm:items-center space">
              <div className="w-full flex flex-row gap-x-2 items-center">
                <h1 className="font-bold text-lightBlack">
                  User Plans
                </h1> 
              </div>

              <div className="w-full mt-4 3md:w-6/20">  
              <Select
                  options={yearOption()}
                  placeholder="Select Year"
                  onChange={(e) => handleInputChange({ target: { name: "year", value: e.value } })}
                  height={40}
                  backgroundColor="#F2F2F2"
                  borderColor="#F2F2F2"
                  isDisabled={loading}
                  value={yearOption().filter((opt) => opt.value === dateSelected.year)}
                />
              </div>
            </div>
            <div className="page-view-graph mt-7.5 w-full"> 
                <StackGraph
                serverData={state?.results || []} 
                freePlan = {state?.freePlan || []}
              /> 
            </div>
            </div>
        </>
    );
    
}

function StackGraph(serverData = []) { 
  console.log(serverData);
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const plan  = serverData?.serverData;
    const exclusive = plan?.unexpired ? labels.map(label => plan?.unexpired[label]) : [];
    const month = plan?.month ? labels.map(label => plan?.month[label]) : [];
    const year = plan?.year ? labels.map(label => plan?.year[label]) : [];
    const free = serverData?.freePlan ? labels.map(label => serverData?.freePlan[label]) : [];
    
    const data = {
        labels,
        datasets: [
          {
            label: 'Free Plan',
            data:  free,
            backgroundColor: 'rgb(220,20,60)',
          },
          {
            label: 'Exclusive Plan',
            data:  exclusive,
            backgroundColor: 'rgb(255, 99, 132)',
          },
          {
            label: 'Monthly Plan',
            data: month,
            backgroundColor: 'rgb(75, 192, 192)',
          },
          {
            label: 'Yearly Plan',
            data:  year,
            backgroundColor: 'rgb(53, 162, 235)',
          },
        ],
      };
  
    const options = {
        plugins: {
          title: {
            display: true,
            text: 'Free & Plan subscription',
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };
  
    return <Bar options={options} data={data} />;
  }

export default UserSubscribe;
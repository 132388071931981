import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Layout from "layouts/layout";
import Table from "components/table/table";
import ListContainer from "components/list/list-container";
import ListHeader from "components/list/list-header";
import _ from "lodash";
import { Profile1 } from "constants/images";
import ActionIconBtn from "components/button/action-btn";
import EditPencilIconSVG from "assets/svg/edit-pencil-icon";
import LockIconSVG from "assets/svg/lock-icon";
import UserCheckIconSVG from "assets/svg/user-check-icon";
import BlockIconSVG from "assets/svg/block-icon";
import { useDialogHook } from "utils/customhooks";
import UserInviteModal from "components/users/user-invite-modal";
import UserBlockModal from "components/users/user-block-modal";
import UserApproveModal from "components/users/user-approve-modal";
import { urlSerializer } from "utils/utils";
import {
  SET_USERS,
  GET_USERS,
  REFRESH_USERS,
} from "redux/modules/user-management/types";
import { useEffect } from "react";

export default function UserManagement() {
  const { http } = global.services;

  const {
    isRequesting,
    initialize,
    loadMore,
    refresh,
    page,
    limit,
    users = [],
    pages = [],
  } = useSelector((state) => state.userManagement);

  const query = useQuery();
  const searchValue = query.get("search");
  const [filterValue, setFilterValue] = useState(searchValue);
  const [selectedRows, setSelectedRows] = useState([]);
  const modal = useDialogHook(UserInviteModal);
  const block = useDialogHook(UserBlockModal);
  const approve = useDialogHook(UserApproveModal);
  const dispatch = useDispatch();

  const getUsers = async (selectedPage = null) => {
    if (isRequesting || !loadMore) return;

    dispatch({ type: GET_USERS, payload: [] });

    try {
      const url = urlSerializer("users/list", {
        search: filterValue,
        page: selectedPage ?? page,
        limit: limit,
      });
      const { data } = await http.get(url);
      const _loadMore = data.data.length > 0 ? true : false;
      // const _page = data.data.length > 0 ? page + 1 : page;
      const _page = data.current_page;
      const _lastPage = data.last_page;

      dispatch({
        type: SET_USERS,
        payload: {
          data: data.data,
          loadMore: _loadMore,
          page: _page,
          lastPage: _lastPage,
        },
      });
    } catch (err) {
      console.log("error@getUsers", err);
    }
  };

  const handleAddEdit = (data = {}) => {
    modal(data, (result) => {
      if (!_.isEmpty(result)) {
        console.log("result ", result);
        if (result === "addOrUpdate") {
          dispatch({
            type: REFRESH_USERS,
            payload: [],
          });
        }
      }
    });
  };

  const handleResetPassword = async (email) => {
    try {
      const { data } = await http.post("auth/forgot-password", { email });
      console.log("handleResetPassword", data);
    } catch (err) {
      console.log("error@handleResetPassword", err);
    }
  };

  const handleApproveUser = (data = {}) => {
    approve(data, (result) => {
      if (!_.isEmpty(result)) {
        if (result === "approve") {
          dispatch({
            type: REFRESH_USERS,
            payload: [],
          });
        }
      }
    });
  };

  const handleBlockUser = (data = {}) => {
    block(data, (result) => {
      if (!_.isEmpty(result)) {
        if (result === "block") {
          dispatch({
            type: REFRESH_USERS,
            payload: [],
          });
        }
      }
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();

    dispatch({
      type: REFRESH_USERS,
      payload: [],
    });
  };

  useEffect(() => {
    if (initialize) getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, initialize]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      nowrap: true,
      key: "name",
      render: (record) => (
        <div className="w-full flex items-center">
          {record.first_name && record.last_name ? (
            <>
              <img
                src={Profile1}
                alt="account"
                className="account-img mr-2.5"
              />
              <p>
                {record.first_name} {record.last_name}
              </p>
            </>
          ) : (
            <p>--</p>
          )}
        </div>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      nowrap: true,
      key: "phone_number",
    },
    {
      title: "Email",
      dataIndex: "email",
      nowrap: true,
      key: "email",
    },
    {
      title: "User Type",
      dataIndex: "userType",
      nowrap: true,
      key: "userType",
      render: (record) => {
        // let color = "green";
        // if (record.role === "invited") {
        //   color = "primary";
        // }
        return <p className={"capitalize"}>{record.role.replace("_", " ")}</p>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      nowrap: true,
      key: "status",
      render: (record) => {
        let color = "green";
        if (record.status === "invited") {
          color = "primary";
        }
        return (
          <p className={`text-${color} font-bold capitalize`}>
            {record.status}
          </p>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      hideSorting: true,
      nowrap: true,
      render: (record) => (
        <>
          <ActionIconBtn
            icon={<EditPencilIconSVG className="m-auto" />}
            onClick={() => handleAddEdit(record)}
          />
          <ActionIconBtn
            icon={<LockIconSVG className="m-auto" />}
            onClick={() => handleResetPassword(record.email)}
          />
          {record.status !== "Verified" && (
            <ActionIconBtn
              icon={<UserCheckIconSVG className="m-auto" />}
              onClick={() => handleApproveUser(record)}
            />
          )}
          <ActionIconBtn
            icon={<BlockIconSVG className="m-auto" />}
            onClick={() => handleBlockUser(record)}
          />
        </>
      ),
    },
  ];
  return (
    <Layout title="Users" routeName="users">
      <div className="main-page-wrapper">
        <ListContainer
          header={
            <ListHeader
              title="List"
              countValue={_.size(users)}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              buttonName="+ Invite User"
              showAddButton={true}
              onClickButton={() => handleAddEdit()}
              onSearch={(e) => handleSearch(e)}
            />
          }
        >
          <Table
            columns={columns}
            dataSource={users}
            pagination={true}
            selectable={true}
            selected={selectedRows}
            onSelected={(selectedRows) => {
              setSelectedRows(selectedRows);
            }}
            perPage={10}
            currentPage={page}
            onHandleChangePage={getUsers}
            pages={pages}
          />
        </ListContainer>
      </div>
    </Layout>
  );
}

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

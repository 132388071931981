const TrashIconSVG = ({ className = "", fill = "#27304E" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3334 7.5V15.8333H6.66669V7.5H13.3334ZM12.0834 2.5H7.91669L7.08335 3.33333H4.16669V5H15.8334V3.33333H12.9167L12.0834 2.5ZM15 5.83333H5.00002V15.8333C5.00002 16.75 5.75002 17.5 6.66669 17.5H13.3334C14.25 17.5 15 16.75 15 15.8333V5.83333Z"
        fill={fill}
      />
    </svg>
  );
};

export default TrashIconSVG;

const LockIconSVG = ({ className = "", fill = "#27304E" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.525 1.66675C15.1333 1.66675 18.8667 5.41675 18.8667 10.0001C18.8667 14.5834 15.1333 18.3334 10.525 18.3334C7.6 18.3334 5.04167 16.8167 3.55 14.5251L4.86667 13.4834C6.04167 15.3917 8.13333 16.6667 10.5333 16.6667C12.3014 16.6667 13.9971 15.9644 15.2474 14.7141C16.4976 13.4639 17.2 11.7682 17.2 10.0001C17.2 8.23197 16.4976 6.53628 15.2474 5.28604C13.9971 4.03579 12.3014 3.33341 10.5333 3.33341C7.13333 3.33341 4.33333 5.88341 3.925 9.16675H6.225L3.10833 12.2751L0 9.16675H2.24167C2.65833 4.95841 6.20833 1.66675 10.525 1.66675ZM12.9917 8.53341C13.4083 8.54175 13.75 8.87508 13.75 9.30008V13.1417C13.75 13.5584 13.4083 13.9084 12.9833 13.9084H8.375C7.95 13.9084 7.60833 13.5584 7.60833 13.1417V9.30008C7.60833 8.87508 7.95 8.54175 8.36667 8.53341V7.69175C8.36667 6.41675 9.40833 5.38341 10.675 5.38341C11.95 5.38341 12.9917 6.41675 12.9917 7.69175V8.53341ZM10.675 6.55008C10.05 6.55008 9.53333 7.05841 9.53333 7.69175V8.53341H11.825V7.69175C11.825 7.05841 11.3083 6.55008 10.675 6.55008Z"
      fill={fill}
    />
  </svg>
);
export default LockIconSVG;

import {
  SET_CATEGORIES,
  SET_CATEGORIES_OPTIONS,
  GET_CATEGORIES,
  REFRESH_CATEGORIES,
} from "./types";
const initialState = {
  initialize: true,
  refresh: false,
  isRequesting: false,
  loadMore: true,
  categories: [],
  limit: 10,
  page: 1,
  pages: [],

  //option
  initializeOption: true,
  options: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REFRESH_CATEGORIES:
      return {
        ...state,
        limit: 10,
        page: 1,
        initialize: true,
        refresh: true,
        isRequesting: false,
        loadMore: true,
        categories: [],

        //option
        initializeOption: true,
        options: [],
      };

    case GET_CATEGORIES:
      return {
        ...state,
        refresh: false,
        isRequesting: true,
      };

    case SET_CATEGORIES:
      // let newCategories = state.categories;

      // payload.data.forEach((c) => {
      //   const category = state.categories.filter((sc) => sc.id === c.id);
      //   if (!category.length) {
      //     newCategories.push(c);
      //   }
      // });

      const tempPages = [];

      if(payload.lastPage > 0 && state.lastPage != payload.lastPage) {
        for(let i = 1; i <= payload.lastPage; i++) {
          tempPages.push(i);
        }
      }

      return {
        ...state,
        isRequesting: false,
        initialize: false,
        page: payload.page,
        loadMore: payload.loadMore,
        // categories: newCategories, // Use this to add more items on the current list when using a load more button or an intersection to load more items.
        categories: payload.data,
        pages: tempPages.length > 0 ? tempPages : state.pages
      };

    case SET_CATEGORIES_OPTIONS:
      return {
        ...state,
        isRequesting: false,
        initializeOption: false,
        options: payload,
      };

    default:
      return state;
  }
};

export default reducer;

import React, { useEffect, useState, useLayoutEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux"; 
import Select from "components/select/select";
import { urlSerializer } from "utils/utils";
import InputField from "components/input/input"; 
import moment from "moment";
import LineGraph from "./line-graph";
 

const MrrChurn = ()=>{ 
    const { http } = global.services;

    const [loading, setLoading] = useState(false);
    const [dateSelected, setDateSelected] = useState({
        year:new Date().getFullYear()
    });
    const [state, stateUse] = useState({
        results:[],
        display_per_month:{},
        total:0
    }); 

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const getProfileViews = async () => {
        setLoading(true);
    
        try {
          const url = urlSerializer("reports/mrr-churn", { 
            year: dateSelected.year
          });
          const { data } = await http.get(url);
          console.log(data);
          stateUse(data); 
        } catch (err) {
          console.log("getProfileViews", err);
        } finally {
          setLoading(false);
        }
      };

      useEffect(()=>{
        getProfileViews();
      },[dateSelected])

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDateSelected((prev) => ({
          ...prev,
          [name]: value, 
        }));
      }; 

      const yearOption = ()=>{
        let year = []
        for(let x = 2020; x <= 2035; x++){
            year.push({
                label:x,
                value:x
            });
        } 
        return year;
    };
    

    return (
        <>
        <div className="w-full bg-white p-2 rounded-md">
            {/* headers */}
            <div className="page-view-heading w-full 2sm:flex 2sm:justify-between 2sm:items-center space">
              <div className="w-full flex flex-row gap-x-2 items-center">
                <h1 className="font-bold text-lightBlack">
                    MRR Churn: ${state.total}
                </h1> 
              </div>

              <div className="w-full mt-4 3md:w-6/20"> 
              <Select
                  options={yearOption()}
                  placeholder="Select Year"
                  onChange={(e) => handleInputChange({ target: { name: "year", value: e.value } })}
                  height={40}
                  backgroundColor="#F2F2F2"
                  borderColor="#F2F2F2"
                  isDisabled={loading}
                  value={yearOption().filter((opt) => opt.value === dateSelected.year)}
                />
              </div>
            </div>
            <div className="page-view-graph mt-7.5 w-full"> 
                <LineGraph
                labels={labels} 
                serverData={state.results || []}
                title={'MRR Churn'}
              /> 
            </div> 
            {
                Object.keys(state?.display_per_month).length > 0 &&
                <div className="flex ml-2 mr-2 w-full p-2">
                    {Object.entries(state?.display_per_month).map(([month, value]) => (
                        <div key={month} className=" month-data w-full border border-red-600 flex justify-center bg-gray-200"> 
                            <div>
                                <p className="text-sm">${value}</p>
                                <h2 className="text-center text-sm">{month}</h2>
                            </div>
                        </div>
                    ))}
                </div>
            } 
            </div>
        </>
    );
    
}
export default MrrChurn;
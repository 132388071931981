import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js"; 
  import { Line } from "react-chartjs-2"; 
  
  ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
export default function LineGraph({ labels, serverData = [], title = "Active" }) { 
    const data = {
      labels,
      datasets: [
        {
          label: title,
          data: serverData || [],
          borderColor: "#8DB4DC",
          backgroundColor: "#8DB4DC",
          pointBorderWidth: 0,
          pointHoverBackgroundColor: "#FFFFFF",
          pointHoverBorderWidth: 10,
          hoverBorderWidth: 10,
          fill: true,
          tension: 0.4,
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          // position: 'nearest',
          // external: customTooltip,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            zeroLineColor: "transparent",
            borderWidth: 0,
          },
        },
        y: {
          grid: {
            borderDash: [8, 4],
            color: "#EDEFF5",
            borderWidth: 0,
          },
          beginAtZero: true,
          ticks: {
            precision: 0,
          },
        },
      },
    };
  
    return <Line options={options} data={data} />;
  }
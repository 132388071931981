import React, { useState } from "react";
import SortIconSVG from "assets/svg/sort-icon";
import LoadingIndicator from "assets/svg/loading-indicator";
import _ from "lodash";
import Checkbox from "components/checkbox/checkbox";
import EmptyIconSVG from "assets/svg/empty-icon";

const thClass = "px-5 py-5 font-semibold ";
const tdClass = "px-5 py-5 ";

const emptyFunc = () => {
  return null;
};

export default function Table({
  columns = [],
  dataSource = [],
  pagination = true,
  pages = [],
  onHandleChangeSorting = emptyFunc,
  onHandleChangePage = emptyFunc,
  perPage = 10,
  showingItems = "",
  currentPage = 1,
  loading = false,
  selectable = false,
  selected = {},
  onSelected = () => {}, 
  loadMore = false,
  loadMoreHandler = emptyFunc,
  loadingMore=false
}) {
  const [checkAll, setCheckAll] = useState(false);
  const handleSort = (enable, key) => {
    if (!enable) {
      onHandleChangeSorting(key);
    }
  };

  const handleSelectAll = (status) => {
    if (!status) {
      onSelected(dataSource);
    } else {
      onSelected([]);
    }
    setCheckAll((prev) => !prev);
  };

  const handleSelectRow = (status, record) => {
    let payload = _.clone(selected);
    let size = _.size(dataSource);
    if (status) {
      payload.push(record);
      onSelected(payload);

      if (_.size(payload) === size) {
        setCheckAll(true);
      }
    } else {
      //remove
      _.remove(payload, (e) => {
        return e.id === record.id;
      });

      if (_.size(payload) < size) {
        setCheckAll(false);
      }

      onSelected(payload);
    }
  };

  const checkedRows = (id) => {
    let find = _.find(selected, function (o) {
      return o.id === id;
    });
    if (_.isEmpty(find)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="relative overflow-x-auto sm:rounded-b-lg">
      <table className="w-full text-sm text-left">
        <thead className="text-sm font-semibold">
          <tr className="bg-thBg border-b border-t border-lighterGray">
            {selectable && (
              <th width="35px" className="pl-5 text-right">
                <Checkbox
                  name={"check-all"}
                  checked={checkAll}
                  justify="end"
                  disabled={_.isEmpty(dataSource)}
                  onChange={() => handleSelectAll(checkAll)}
                />
              </th>
            )}
            {(columns || []).map((value) => {
              let {
                title,
                key,
                align = "left",
                width = "",
                hideSorting = false,
              } = value;
              return (
                <th key={key} className={thClass + `text-${align} ${width}`}>
                  <div
                    className="flex flex-row items-center space-x-2 cursor-pointer"
                    onClick={() => handleSort(hideSorting, key)}
                  >
                    <p className="text-lightBlack">{title}</p>
                    {!hideSorting && (
                      <SortIconSVG className="mb-1" fill="gray" />
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        {_.isEmpty(dataSource) && !loading ? (
          <tbody className="text-base">
            <tr key="no-data" className="bg-white">
              <td colSpan={_.size(columns) + 1} className={tdClass + `text-center`}>
                <div className="w-full flex justify-center items-center">
                  <EmptyIconSVG className="w-12 h-12" />
                </div>
                <p className="text-black opacity-70 text-sm mt-2">No Data</p>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody className="text-base">
            {loading ? (
              <tr key="loading" className="bg-white">
                <td
                  colSpan={_.size(columns)}
                  className={tdClass + `text-center`}
                >
                  <div className="flex items-center justify-center w-full">
                    <LoadingIndicator className="w-5 h-5 mr-3 text-secondary" />
                    <p className="text-black opacity-70">Loading ...</p>
                  </div>
                </td>
              </tr>
            ) : (
              dataSource.map((record, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white border-b border-lighterGray"
                  >
                    {selectable && (
                      <td width="35px" className="pl-5">
                        <Checkbox
                          name={`check-element-${index}`}
                          checked={checkedRows(record.id)}
                          onChange={(e) =>
                            handleSelectRow(e.target.checked, record)
                          }
                        />
                      </td>
                    )}
                    {(columns || []).map((column) => {
                      let {
                        key,
                        align = "left",
                        dataIndex,
                        render,
                        nowrap = false,
                      } = column;
                      if (render) {
                        return (
                          <td
                            key={key}
                            className={
                              tdClass +
                              `text-${align} ${nowrap && "whitespace-nowrap"} ${
                                key === "action" && "w-60"
                              }`
                            }
                          >
                            {render(record)}
                          </td>
                        );
                      } else {
                        return (
                          <td
                            key={key}
                            className={
                              tdClass +
                              `text-${align} ${nowrap && "whitespace-nowrap"}`
                            }
                          >
                            <p>{record[dataIndex] || "--"}</p>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        )}
      </table>
      {
      (loadMore && !pagination && !loading)  &&
      <div className="flex justify-center items-center p-3 bg-white"> 
        {
              loadingMore ? <LoadingIndicator className="w-5 h-5 mr-3 text-secondary" />
              :
              <p className="text-sm opacity-60 cursor-pointer hover:text-primary hover:underline" onClick={()=>loadMoreHandler()}>
                {`Show more`}
              </p>
          }
         
      </div>
      }
      {pagination && (
        <nav className="flex justify-between items-center p-3 bg-white">
          <div>
            <p className="text-sm opacity-60">Rows per page: {perPage}</p>
          
          </div>
          {/* <div>
            <p className="text-sm opacity-60">
              {`Showing ${!loading && showingItems} items`}
            </p>
          </div> */}
          <ul className="flex flex-row space-x-1.5">
            {pages.map((page) => (
              <li
                key={page}
                onClick={() => onHandleChangePage(page)}
                className={`page-link ${currentPage === page && "active"}`} //${state.data.current_page === page ? "active" : ""}
              >
                {page}
              </li>
            ))}
          </ul>
        </nav>
      )}
    </div>
  );
}

import {
  SET_TEAM_MEMBERS,
  GET_TEAM_MEMBERS,
  REFRESH_TEAM_MEMBERS,
} from "./types";

const initialState = {
  page: 1,
  limit: 10,
  initialize: true,
  refresh: false,
  isRequesting: false,
  loadMore: true,
  teamMembers: [],
  pages: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REFRESH_TEAM_MEMBERS:
      return {
        ...state,
        page: 1,
        limit: 10,
        initialize: true,
        refresh: true,
        isRequesting: false,
        loadMore: true,
        teamMembers: [],
      };

    case GET_TEAM_MEMBERS:
      return {
        ...state,
        refresh: false,
        isRequesting: true,
      };

    case SET_TEAM_MEMBERS:
      const tempPages = [];

      if(payload.lastPage > 0 && state.lastPage != payload.lastPage) {
        for(let i = 1; i <= payload.lastPage; i++) {
          tempPages.push(i);
        }
      }

      return {
        ...state,
        isRequesting: false,
        initialize: false,
        page: payload.page,
        loadMore: payload.loadMore,
        // teamMembers: [...state.teamMembers, ...payload.data],  // Use this to add more items on the current list when using a load more button or an intersection to load more items.
        teamMembers: payload.data,
        pages: tempPages.length > 0 ? tempPages : state.pages
      };

    default:
      return state;
  }
};

export default reducer;

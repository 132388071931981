import React,{useState, useEffect} from "react";
import { connect } from "react-redux";
import Layout from "layouts/layout"; 
import { useHistory,Link   } from "react-router-dom";
import _ from "lodash"; 
import { urlSerializer } from "utils/utils";  
import Input from "components/input/input";
import { useParams } from 'react-router-dom';
 import moment from "moment";
 import { FiLink } from "react-icons/fi";
 import { RiAttachment2 } from "react-icons/ri";
 import { MdArrowBackIos } from "react-icons/md";
 import Button from "components/button/button";
 import { FiUserCheck,FiUserX } from "react-icons/fi";
 import ModalReject from "./reject-modal"; 
import { useDialogHook } from "utils/customhooks"; 
import toast from "react-hot-toast";
import LoadingIndicator from "assets/svg/loading-indicator";
 
//end charts

const GroupInfo = ({ user }) => {

    const { id } = useParams();
    const history = useHistory();
    const { http } = global.services; 
    const borderColor = {borderColor:'#e5e5e5'}
    const [info, setInfo] = useState({}); 
    const modalReport = useDialogHook(ModalReject);
    const [isLoading,setIsLoading] = useState(false);

    useEffect(()=>{
        groupInfo()
    },[]);

    const groupInfo = async () => { 
    
        try {
          const url = urlSerializer(`group/${id}`);
          const { data } = await http.get(url);  
          console.log(data) 
          setInfo(data)
       
        } catch (err) {
          console.log("error@groupGroup", err);
        }
      };
    const openLink = (file)=>{   
        let url = file?.attachment;
        const isHttp = url.startsWith('http://');
        const isHttps = url.startsWith('https://');
        if (!isHttp && !isHttps) {
            url = 'https://' + url;
          } 
          window.open(url, '_blank');
    }
    const rejectTeam = ()=>{
        modalReport({info}, (result)=>{
            if(result?.success){
                setInfo({...info, team_status:'reject'})
            } 
        });
    }

    const approveTeam = async() => {
        try{
            const payload = {
              id:id,
              team_status:'Approved'
            }
            setIsLoading(true) 
            const {data } = await http.post(`group/status`,payload);
            toast.success(data.message); 
            setInfo({...info, team_status:data.team_status})
            setIsLoading(false) 
          }catch(err){
            toast.error(`It appears that something went wrong`); 
            setIsLoading(false) 
          }finally{ 
            setIsLoading(false) 
          }
    }

    const downloadFile = async(e,file)=>{
        e.stopPropagation();  
            try { 
                const payload = {
                    filename:file?.media?.key
                }
                const response = await http.post(`downloadFile`, payload, {
                    responseType: 'blob',
                }); 
                const blob = new Blob([response.data], { type: response.data.type });
                const url = window.URL.createObjectURL(blob) 
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${file?.media?.key}`);  
                document.body.appendChild(link); 
 
                link.click();
 
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading the file', error);
            }
    }
    
  return (
    <Layout title="Group" routeName="group" className="p-0 flex-col"> 
    <div className="flex justify-between bg-white w-full h-18 p-2">
        <div className="flex gap-2 items-center">
            <div className="ml-2 cursor-pointer" 
            onClick={() => history.push("/group")}
            >
                <MdArrowBackIos/>
            </div>
            <div className="pr-1 my-2">{info?.team_name}</div>
            <div className="font-bold text-xs capitalize">{info?.team_status || 'Pending'}</div>
        </div>
        {
            info?.team_status === 'pending' || info?.team_status === null &&
            <div className="flex gap-2 items-center">
            <Button
                buttonName="Reject"
                buttonClass="bg-danger py-2 px-9"
                buttonTextClass="text-white text-sm buttonTextClass flex gap-2" 
                icon={<FiUserX className="text-white"/>}
                iconPosition="left"
                onClick={rejectTeam}
                disabled={isLoading}
            />
            <Button
                disabled={isLoading}
                key="save-btn-category"
                buttonName="Approve"
                buttonClass="bg-primary py-2 px-12"
                buttonTextClass="text-white text-sm buttonTextClass flex gap-2" 
                icon={<div className="text-white"><FiUserCheck/></div>}
                iconPosition="left"
                onClick={approveTeam}
                loadingIcon={
                    isLoading ? (
                    <LoadingIndicator className="text-white w-4 h-4 ml-2" />
                    ) : null
                }
            />
        </div>
        }
        
    </div>
        <div className="main-page-wrapper">
        <div className="w-full px-2 py-2 1sm:flex justify-between gap-4">
            <div className="bg-white rounded-md shadow-lg flex-1 w-full">
                <div className="px-2 py-3 border-b" style={{...borderColor}}>
                    Group details
                </div>
                <div className="p-2">
                <Input
                    inputContainerClass="form-input-container"
                    value={info?.team_name || ""}
                    name="name"
                    outsideLabel="Team Name"
                    placeholder="Enter Team"
                    outsideLabelClass="text-sm font-semibold text-lightBlack"
                    noLabelPaddingY="0.8rem"
                    type="text" 
                    errorMessagePosition="bottom"
                    disabled={true}
                    />
                <Input
                    inputContainerClass="form-input-container"
                    value={info?.address || ""}
                    name="name"
                    outsideLabel="Formation Location"
                    placeholder="Enter Team"
                    outsideLabelClass="text-sm font-semibold text-lightBlack" 
                    type="text" 
                    errorMessagePosition="bottom" 
                    disabled={true}
                    />

                <Input
                    inputContainerClass="form-input-container"
                    value={moment(info?.birthdate || 0).format('MMMM DD, YYYY') || ""}
                    name="name"
                    outsideLabel="Formation Date"
                    placeholder="Enter Team"
                    outsideLabelClass="text-sm font-semibold text-lightBlack" 
                    type="text" 
                    errorMessagePosition="bottom" 
                    disabled={true}
                    />

                <Input
                    inputContainerClass="form-input-container"
                    value={info?.user?.email || ""}
                    name="name"
                    outsideLabel="Email Address"
                    placeholder="Formation Date"
                    outsideLabelClass="text-sm font-semibold text-lightBlack" 
                    type="text" 
                    errorMessagePosition="bottom"
                    disabled={true}
                    />

                <Input
                    inputContainerClass="form-input-container"
                    value={info?.team_status || "Pending"}
                    name="name"
                    outsideLabel="Status"
                    placeholder="Status"
                    outsideLabelClass="text-sm font-semibold text-lightBlack" 
                    type="text" 
                    errorMessagePosition="bottom"
                    disabled={true}
                    />
                </div>
            </div>
            <div className="bg-white rounded-md shadow-lg flex-1 w-full">
                <div className="px-2 py-3 border-b-2" style={{...borderColor}}>
                        Team Members
                </div>
                <div className="divide-y divide-[#e5e5e5] mx-2">
                    {
                        info?.profile_type?.members &&
                        JSON.parse(info?.profile_type?.members).map((member, index) => {
                            return (
                                <div className="flex items-center p-2 border-b" style={{...borderColor}} key={index}>
                                    <div className="flex-1">
                                        <p className="text-sm">{member?.name}</p>
                                        <p className="text-xs text-slate-200">{member?.role}</p>
                                    </div>
                                    <div className="text-right"> 
                                        {member?.dateSE}
                                    </div>
                                </div>
                            )
                        })
                    } 
                </div>
            </div>
            <div className="bg-white rounded-md shadow-lg flex-1 w-full">
                <div className="px-2 py-3 border-b-2" style={{...borderColor}}>
                    Proofs
                </div>
                <div className="m-2 border-2 rounded-md" style={{...borderColor}}>
                    <div className="m-2 border-b font-bold text-sm" style={{...borderColor}}>Wirtten Text</div>
                    <div className="m-3 text-xs">
                        {info?.written_proof}
                    </div>
                </div>
                <div className="m-2 border-2 rounded-md" style={{...borderColor}}>
                    <div className="m-1 border-b font-bold text-sm" style={{...borderColor}}>Files</div>
                    <div className="mx-1"> 
                        {
                            info?.team_files && 
                            info?.team_files.map( (file, index) => 
                            <div className="flex justify-between text-sm mx-2 border-b" style={{...borderColor}} key={index}>
                                {
                                    file?.type === 'file' ?
                                    <>
                                    <div className="flex flex-1 gap-1 py-4">
                                        <div className="text-primary"> 
                                            <RiAttachment2/>
                                        </div>
                                        <div> 
                                            Attachment {index +1 }
                                        </div>
                                    </div> 
                                    <div className="text-primary font-bold flex items-center text-xs cursor-pointer"
                                    onClick={(e)=>{downloadFile(e,file)}}
                                    > 
                                        Download
                                    </div>
                                    </>
                                    :
                                    <>
                                    <div className="flex flex-1 gap-1 py-4">
                                        <div className="text-primary"> 
                                            <FiLink/>
                                        </div>
                                        <div> 
                                            Link {index+1}
                                        </div>
                                    </div> 
                                    <div className="text-primary font-bold flex items-center text-xs cursor-pointer"
                                    onClick={()=> openLink(file)}
                                    > 
                                        Open Link
                                    </div>
                                    </>
                                }
                            </div>
                            )

                        }
                    </div>
                </div>
            </div>
        </div>
        </div>
    </Layout>
  );
}; 

export default GroupInfo
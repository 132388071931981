import Layout from "layouts/layout";
import ListContainer from "components/list/list-container";
import ListHeader from "components/list/list-header";
import Table from "components/table/table";
import { useEffect,useState,useRef } from "react";
import { urlSerializer } from "utils/utils";
import {
  SET_REPORT_MILESTONES,
  REFRESH_REPORT_MILESTONES,
} from "redux/modules/report-milestone/types";
import { useSelector, useDispatch } from "react-redux";
import { BiHide } from "react-icons/bi";
import { FaEllipsisH } from "react-icons/fa";
import { PiWarningDiamond } from "react-icons/pi";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";
import { HiOutlineArrowUturnRight } from "react-icons/hi2"
import _ from "lodash";
import Popup from "reactjs-popup";
import { useDialogHook } from "utils/customhooks";
import Milestone from "./milestone";
import ModalReport from "./modalReport";
import LoadingIndicator from "assets/svg/loading-indicator";

const Reports = ()=>{
  const { http } = global.services;
  const [filterValue, setFilterValue] = useState("");
  const [showMoreOption, setShowMoreOption] = useState(); 
  const [isLoading,setIsLoading] = useState(false); 
  const [loadingMore,setLoadingMore] = useState(false);
  const {
      initialize,
      refresh,
      page,
      limit, 
      reportMilestones = [],
      loadMore,
  } = useSelector((state)=>state.reportMilestones);
  const optionRef = useRef();
  const dispatch = useDispatch();
  const milestoneModal = useDialogHook(Milestone);
  const modalReport = useDialogHook(ModalReport);

  useEffect(()=>{
  const getReportMilestones = async ()=>{ 
    try { 
      setIsLoading(true)
      const url = urlSerializer("reports/list", {
        page,
        limit,
        search: filterValue,
      });
      const { data } = await http.get(url);

      const _loadMore = data.data.length > 0 ? true : false;
      const _page = data.data.length > 0 ? page + 1 : page;

      dispatch({
        type: SET_REPORT_MILESTONES,
        payload: {
          page: _page,
          data: data.data,
          loadMore: _loadMore, 
        },
      });
    } catch (err) {
      console.log("error@getTeamMembers", err);
    }finally{
      setIsLoading(false);
    }
  }
  if(initialize)getReportMilestones();

  },[refresh,initialize]);

  useEffect(()=>{
    window.addEventListener(
      "scroll",
      ()=>{
        if(optionRef.current){ 
          optionRef.current.close()
        }
      },
      true
    );
    return ()=> window.removeEventListener('scroll', ()=>{});
  },[])

  const showMilestone = (milestone)=>{
    if(optionRef.current){ 
      optionRef.current.close()
    }
    milestoneModal({milestone}, (result)=>{
      if (!_.isEmpty(result)) { 
      }
    });
  }

  const loadMoreHandler = async()=>{ 

    try { 
      setLoadingMore(true)
      const url = urlSerializer(`reports/list`, {
        page,
        limit,
        search: filterValue,
      });
      const { data } = await http.get(url); 
      const _loadMore = (reportMilestones.length + data.data.length) < data.total ? true : false;
      const _page = _loadMore ? page + 1 : page;

      dispatch({
        type: SET_REPORT_MILESTONES,
        payload: {
          page: _page,
          data: data.data,
          loadMore: _loadMore, 
          refresh:false
        },
      });
    } catch (err) {
      console.log("error@getTeamMembers", err);
    }finally{
      setLoadingMore(false);
    }
  }

  const showReport = (details,key)=>{
    if(optionRef.current){ 
      optionRef.current.close()
    }
    const reportStaus = {
      suspendedAccount:{
        title:"Suspend Account",
        status:"suspend",
        desc:"suspend"
      },
      addAdultBadge:{
        title:"Add Adult Badge",
        status:"add badge",
        desc:"add adult badge"
      },
      hideMilestone:{
        title:"Hide Milestone",
        status:"hide milestone", 
        desc:"hide the milestone"
      }
    }  
    details.instruction = reportStaus[key];
    modalReport({details}, (result)=>{});
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      nowrap: true,
      key: "name",
      render: (record) => (
        <div className="w-full flex items-center">
          {record.account_report.first_name && record.account_report.last_name ? (
            <>
              <img
                src={record.account_report?.profile?.profile_image_webp_url || record.account_report?.profile?.profile_image_url || null}
                alt="account"
                className="account-img mr-2.5 rounded-full"
              />
              <p>
                {record.account_report.first_name} {record.account_report.last_name}
              </p>
            </>
          ) : (
            <p>--</p>
          )}
        </div>
      ),
    }, 
    {
      title: "Email",
      dataIndex: "email",
      nowrap: true,
      key: "email",
      render:(record) =>(
        <p>
          {record.account_report.email}
        </p>
      )
    }, 
    {
      title: "Type",
      dataIndex: "type",
      nowrap: true,
      key: "type",
      render:(record)=>(
        <p className="capitalize">
          {record.type}
        </p>
      )
    },
    {
      title: "Problem",
      dataIndex: "problem",
      nowrap: true,
      key: "problem",
    },
    {
      title: "Comments",
      dataIndex: "comments",
      nowrap: true,
      key: "comments",
    },
    {
      title: "Status",
      dataIndex: "status",
      nowrap: true,
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      hideSorting: true,
      nowrap: true,
      render: (record) => (
        <>
          <Popup
              ref={optionRef}
              position="bottom right"
              open={showMoreOption}
              onClose={() => setShowMoreOption(false)}
              arrow={false}
              contentStyle={{ marginTop: 10, marginLeft: 5 }}
              trigger={
                <button
                  className="py-2 bg-thBg rounded-md hidden 1sm:block 3md:py-4 px-5"
                  onClick={() => setShowMoreOption((open) => !open)}>
                  <FaEllipsisH className="m-auto" />
                </button>
              }>
                <div className="flex flex-col shadow-lg rounded-md bg-white">
                  <ul className=""> 
                      { record.type === 'milestone' &&
                        <li className="hover:bg-gray hover:rounded-md" > 
                          <button onClick={()=>showMilestone(record?.milestone)}  
                            className={`flex flex-row items-center text-sm px-4 py-2 gap-2`}>
                            <HiOutlineArrowUturnRight className="text-lg"/> 
                            View Milestone
                          </button>
                        </li>
                      } 
                      <li className="hover:bg-gray hover:rounded-md" > 
                        <button onClick={()=>showReport(record,"suspendedAccount")}
                          className={`flex flex-row items-center text-sm px-4 py-2 gap-2`}>
                          <MdOutlineDoNotDisturbAlt className="text-lg"/> 
                          Suspended Account
                        </button>
                      </li> 
                      <li className="hover:bg-gray hover:rounded-md" > 
                        <button onClick={()=>showReport(record,"addAdultBadge")} 
                          className={`flex flex-row items-center text-sm px-4 py-2 gap-2`}>
                          <PiWarningDiamond className="text-lg"/> 
                          Add Adult Badge
                        </button>
                      </li> 
                      <li className="hover:bg-gray hover:rounded-md" > 
                        <button onClick={()=>showReport(record,"hideMilestone")} 
                          className={`flex flex-row items-center text-sm px-4 py-2 gap-2`}>
                          <BiHide className="text-"/> 
                          Hide milestone
                        </button>
                      </li> 
                  </ul>
                </div>
            </Popup>
        </>
      ),
    },
  ];

    return(
        <Layout title="Report Milestones" routeName="reports">
        <div className="main-page-wrapper">
          <ListContainer
            header={
              <ListHeader
                title="List"
                countValue={_.size(reportMilestones)}
                filterValue={filterValue}
                setFilterValue={setFilterValue} 
                showAddButton={false}
                // onClickButton={() => handleAddEdit()}
              />
            }
          > 
            <Table
            loadingMore={loadingMore}
            loading={isLoading}
            columns={columns}
            dataSource={reportMilestones}
            pagination={false}
            selectable={false} 
            loadMore={loadMore} 
            loadMoreHandler = {()=>loadMoreHandler()} 
          /> 
          </ListContainer>
        </div>
      </Layout>
    )
}

export default Reports;
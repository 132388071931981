import React from "react";
import "./styles.css";

/**
 * buttonName = for button text inside button container
 * buttonWidth = custom width for button
 * buttonClass = customized class for button
 * buttonTextClass = custom classname for button text inside
 * onClick = function for clicking the button
 */

const Button = ({
  buttonName = "",
  buttonWidth,
  buttonClass = "defaultButton",
  buttonRadius = "6px",
  buttonTextClass = "defaultButtonText",
  hover = "",
  iconPosition = "right",
  icon = null,
  loadingIcon = null,
  onClick = () => {},
  disabled = false,
  type = "button"
}) => {
  return (
    <button
      type={type}
      className={buttonClass + ` ${!disabled && `hover:${hover}`}`}
      disabled={disabled}
      onClick={onClick}
      style={
        buttonWidth
          ? {
              width: buttonWidth,
              borderRadius: buttonRadius,
              opacity: (disabled || loadingIcon) && 0.5,
              cursor: disabled && "default",
            }
          : {
              borderRadius: buttonRadius,
              opacity: (disabled || loadingIcon) && 0.5,
              cursor: disabled && "default",
            }
      }
    >
      <div className={buttonTextClass}>
        <>{iconPosition === "left" && icon}</>
        <>{buttonName}</>
        <>{iconPosition === "right" && icon}</>
        {loadingIcon && loadingIcon}
      </div>
    </button>
  );
};

export default Button;

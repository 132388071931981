import { combineReducers, createStore, applyMiddleware } from "redux";
import subscribeActionMiddleware from "redux-subscribe-action";
import UserReducer from "./modules/user/reducer";
import NotificationReducer from "./modules/notification/reducer";
import TransactionReducer from "./modules/transaction-management/reducer";
import CategoryReducer from "./modules/category-management/reducer";
import RoleReducer from "./modules/role-management/reducer";
import UserManagementReducer from "./modules/user-management/reducer";
import FaqManagementReducer from "./modules/faq-management/reducer";
import TeamMembersReducer from "./modules/team-members/reducer";
import ReportMilestonesReducer from "./modules/report-milestone/reducer";
import GroupReducer from "./modules/group/reducer";

const allReducers = combineReducers({
  user: UserReducer,
  notifications: NotificationReducer,
  transactions: TransactionReducer,
  category: CategoryReducer,
  role: RoleReducer,
  userManagement: UserManagementReducer,
  faqManagement: FaqManagementReducer,
  teamMembers: TeamMembersReducer,
  reportMilestones: ReportMilestonesReducer,
  group: GroupReducer
});

const store = createStore(
  allReducers,
  applyMiddleware(subscribeActionMiddleware)
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export default store;
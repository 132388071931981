const EditPencilIconSVG = ({ className = "", fill = "#27304E" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 16.6667H4.16667C3.94566 16.6667 3.73369 16.7545 3.57741 16.9108C3.42113 17.0671 3.33334 17.279 3.33334 17.5C3.33334 17.7211 3.42113 17.933 3.57741 18.0893C3.73369 18.2456 3.94566 18.3334 4.16667 18.3334H15.8333C16.0544 18.3334 16.2663 18.2456 16.4226 18.0893C16.5789 17.933 16.6667 17.7211 16.6667 17.5C16.6667 17.279 16.5789 17.0671 16.4226 16.9108C16.2663 16.7545 16.0544 16.6667 15.8333 16.6667ZM4.16667 15H4.24167L7.71667 14.6834C8.09733 14.6455 8.45337 14.4777 8.725 14.2084L16.225 6.70838C16.5161 6.40085 16.6734 5.99047 16.6625 5.56716C16.6516 5.14385 16.4733 4.74213 16.1667 4.45004L13.8833 2.16671C13.5853 1.88679 13.1948 1.72618 12.7861 1.71542C12.3774 1.70467 11.979 1.84452 11.6667 2.10838L4.16667 9.60838C3.89731 9.88002 3.72959 10.236 3.69167 10.6167L3.33334 14.0917C3.32211 14.2138 3.33795 14.3368 3.37972 14.452C3.42149 14.5673 3.48817 14.6719 3.575 14.7584C3.65287 14.8356 3.74522 14.8967 3.84675 14.9382C3.94828 14.9797 4.057 15.0007 4.16667 15ZM12.725 3.33338L15 5.60838L13.3333 7.23338L11.1 5.00004L12.725 3.33338ZM5.30834 10.7584L10 6.10004L12.25 8.35005L7.58334 13.0167L5.08334 13.25L5.30834 10.7584Z"
        fill={fill}
      />
    </svg>
  );
};

export default EditPencilIconSVG;

export default function ContentHeader({
  billTo = ["Address Line 1", "City, State, Zip Code", "ABN No.0000001"],
  billFrom = ["Address Line 1", "City, State, Zip Code", "ABN No.0000001"],
  taxTo = "",
  taxFrom = "",
}) {
  return (
    <div className="w-full bg-white py-7 px-9 grid grid-cols-2">
      <div className="w-full">
        <p className="text-10 text-mediumGray">
          <span className="font-semibold text-black">Bill To:</span>
          <br />
          {billTo[0]}
          <br />
          {billTo[1]}
          <br />
          {billTo[2]}
        </p>
        <p className="text-10 font-semibold mt-6">
          Tax Number: <br />
          <span className="font-normal">{taxTo}</span>
        </p>
      </div>
      <div className="w-full">
        <p className="text-10 text-mediumGray">
          <span className="font-semibold text-black">Bill From:</span>
          <br />
          {billFrom[0]}
          <br />
          {billFrom[1]}
          <br />
          {billFrom[2]}
        </p>
        <p className="text-10 font-semibold mt-6">
          Tax Number: <br />
          <span className="font-normal">{taxFrom}</span>
        </p>
      </div>
    </div>
  );
}

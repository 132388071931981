const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
const PUSHER_API_KEY = process.env.REACT_APP_PUSHER_API_KEY
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER

export {
	API_ENDPOINT,
	PUSHER_API_KEY,
	GOOGLE_MAPS_API_KEY,
	PUSHER_CLUSTER
}
import React from "react";

const Modal = ({
  footer = null,
  width = "w-full",
  wrapperClassName = "",
  contentClassName = "p-4",
  footerClass = "",
  ...props
}) => {
  return (
    <div
      className="fixed z-50 inset-0 overflow-y-auto modal-animation custom-scroll"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
          aria-hidden="true"
        ></div>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className={`${width} relative inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle ${wrapperClassName}`}
          // style={{ width:  }}
        >
          {/*  content here */}
          <div className={`bg-white overflow-visible ${contentClassName}`}>
            {props?.children}
          </div>
          {/* footer here */}
          {footer && (
            <div className={`w-full pl-10 pr-7 py-3.5 ${footerClass}`}>
              {footer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;


const errors = {
	required: 'The :field field is required.'
}

const regexes = {
	disableWhitespaces: "^[A-Za-z][A-Za-z0-9]*$"
}


class ValidationService {

	message (field, error = null) {
		if (error)
			return errors[error]?.replace(':field', field)
		return null
	}

	regex (type) {
		if (type)
			return regexes[type]
		return null
	}

}

export default new ValidationService()
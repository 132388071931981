import React, { useMemo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip);

const DoughnutCharts = ({ labels, datasets }) => {
  const options = {
    responsive: true,
  };

  const data = useMemo(() => {
    return {
      labels,
      datasets: [datasets],
    };
  }, [labels, datasets]);

  return (
    <div className="w-full">
      <Doughnut options={options} data={data} />
    </div>
  );
};

export default DoughnutCharts;

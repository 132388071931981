import { SET_USERS, GET_USERS, REFRESH_USERS } from "./types";
const initialState = {
  initialize: true,
  refresh: false,
  isRequesting: false,
  loadMore: true,
  users: [],
  limit: 10,
  page: 1,
  pages: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REFRESH_USERS:
      return {
        ...state,
        limit: 10,
        page: 1,
        initialize: true,
        refresh: true,
        isRequesting: false,
        loadMore: true,
        users: [],
      };

    case GET_USERS:
      return {
        ...state,
        refresh: false,
        isRequesting: true,
      };

    case SET_USERS:
      // let newUsers = state.users;

      // payload.data.forEach((s) => {
      //   const user = state.users.filter((su) => su.id === s.id);
      //   if (!user.length) {
      //     newUsers.push(s);
      //   }
      // });

      const tempPages = [];

      if(payload.lastPage > 0 && state.lastPage != payload.lastPage) {
        for(let i = 1; i <= payload.lastPage; i++) {
          tempPages.push(i);
        }
      }

      return {
        ...state,
        isRequesting: false,
        initialize: false,
        page: payload.page,
        loadMore: payload.loadMore,
        // users: newUsers, // Use this to add more items on the current list when using a load more button or an intersection to load more items.
        users: payload.data,
        pages: tempPages.length > 0 ? tempPages : state.pages
      };

    default:
      return state;
  }
};

export default reducer;

import React, { useState } from "react";
import Input from "components/input/input";
import toast from "react-hot-toast";
import Button from "components/button/button";
import { Link, useHistory } from "react-router-dom";
import userActions from "redux/modules/user/actions";
import { connect } from "react-redux";
import LoadingIndicator from "assets/svg/loading-indicator";
import { LogoWhite } from "constants/images";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Login = ({ setUserData }) => {
  const history = useHistory();
  const { http, localstorage } = global.services;
  const [state, setState] = useState({
    email: "",
    password: "",
    showPassword: false,
    loading: false,
    invalidateRecaptcha: true,
    validationErrors: null,
  });
  const [verificationState, setVerificationState] = useState({
    code: "",
    resend: false,
    validationError: null
  });
  const [show2FA, setShow2FA] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleCodeChange = (e) => {
    const { value } = e.target;
    setVerificationState((prev) => ({
      ...prev,
      code: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setState((prev) => ({ ...prev, loading: true }));

    try {
      const req = await http.post("auth/login", state);
      const response = req.data;
      if (response.verify) {
        setShow2FA(true);
      } else {
        if (response.user.role !== "customer") {
          localstorage.set("user_data", JSON.stringify(response));
          setUserData(response);

          return history.replace("/");
        } else {
          toast.error("This site is only accessible by admin users");
        }
      }
    } catch (error) {
      if (error.status === 422)
        return setState((prev) => ({ ...prev, validationErrors: error.data }));

      toast.error(error?.data?.message || "It appears that something went wrong");
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const verify = async (e) => {
    e.preventDefault();
    setState((prev) => ({ ...prev, loading: true }));

    try {
      const req = await http.post("auth/two-factor/verification", {
        email: state.email,
        password: state.password,
        code: verificationState.code,
      });
      const response = req.data;

      localstorage.set("user_data", JSON.stringify(response));
      setUserData(response);

      return history.replace("/");
    } catch (error) {
      if (error.status === 401) {
        const message = error.data?.message;
        return setVerificationState((prev) => ({ ...prev, validationError: message }));
      }
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  }

  const resend = async () => {
    setVerificationState((prev) => ({...prev, resend: true }));
    try {
      const req = await http.post("auth/two-factor/resend", {
        email: state.email,
      });

      toast.success(req?.data?.message);
    } catch (error) {
      if (error.status === 401) {
        const message = error.data?.message;
        return setVerificationState((prev) => ({ ...prev, validationError: message }));
      }
    } finally {
      setVerificationState((prev) => ({...prev, resend: false }));
    }
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>Login - Milestones Page Administrator</title>
      </Helmet>
      <div className="form-page-wrapper">
        <div className="form-logo-container">
          <img
            src={LogoWhite}
            alt="component-brand-logo"
            className="form-logo"
          />
        </div>

        <div className="form-container">
          {show2FA ?
            <>
              <h2 className="self-start text-3xl font-bold mb-2">Verify that it's you</h2>
              <p className="self-start mb-6">We sent a one time verification code to the mobile number attached to your account.</p>
              <form
                className="w-full"
                onSubmit={verify}
              >
                <Input
                  inputClass="text-center tracking-widest"
                  inputContainerClass="form-input-container"
                  value={verificationState.code}
                  autoFocus={true}
                  name="code"
                  outsideLabel="Enter Verification Code"
                  outsideLabelClass="text-sm font-bold text-darkerGray"
                  noLabelPaddingY="1rem"
                  type="text"
                  onChange={handleCodeChange}
                  errorMessage={verificationState.validationError}
                  required
                />
                <p className="text-center mb-6">Didn't get the code?
                  <Button
                    buttonName="Resend Code"
                    disabled={state.loading || verificationState.resend}
                    buttonTextClass="text-sm text-primary font-semibold"
                    buttonRadius="6px"
                    buttonClass="ml-2"
                    onClick={resend}
                    type="button"
                  />
                </p>
                <div className="flex flex-col lg:flex-row gap-4">
                  <Button
                    buttonName="Back"
                    disabled={state.loading || verificationState.resend}
                    buttonTextClass="text-sm text-black font-semibold"
                    buttonRadius="6px"
                    buttonClass="relative bg-thBg p-5"
                    buttonWidth="100%"
                    onClick={() => setShow2FA(false)}
                    type="button"
                  />
                  <Button
                    buttonName="Verify and Sign In"
                    disabled={state.loading || verificationState.resend}
                    buttonTextClass="text-sm text-white font-semibold"
                    buttonRadius="6px"
                    buttonClass="relative bg-primary p-5"
                    buttonWidth="100%"
                    type="submit"
                    icon={
                      <div className="absolute right-3 bottom-0 top-0 flex items-center">
                        {state.loading && (
                          <LoadingIndicator className="text-white w-5 h-5" />
                        )}
                      </div>
                    }
                  />
                </div>
              </form>
            </>
            : <>
              <h2 className="form-column-title">Sign In</h2>
              <form
                className="form-column-container"
                id="login-page-component"
                onSubmit={handleLogin}
              >
                <Input
                  inputContainerClass="form-input-container"
                  value={state.email}
                  autoFocus={true}
                  name="email"
                  outsideLabel="E-mail Address"
                  outsideLabelClass="text-sm font-bold text-darkerGray"
                  noLabelPaddingY="1rem"
                  type="text"
                  onChange={handleInputChange}
                  errorMessage={state.validationErrors?.email}
                />
                <Input
                  inputContainerClass="form-input-container"
                  value={state.password}
                  outsideLabel="Password"
                  outsideLabelClass="text-sm font-bold text-darkerGray"
                  noLabelPaddingY="1rem"
                  name="password"
                  masked={true}
                  visible={state.showPassword}
                  onChangeVisible={() =>
                    setState((prev) => ({
                      ...prev,
                      showPassword: !prev.showPassword,
                    }))
                  }
                  type={state.showPassword ? "text" : "password"}
                  onChange={handleInputChange}
                  errorMessage={state.validationErrors?.password}
                />

                <Button
                  buttonName="Sign In"
                  disabled={state.loading}
                  buttonTextClass="text-sm text-white font-semibold"
                  buttonRadius="6px"
                  buttonClass="relative bg-primary p-5"
                  buttonWidth="100%"
                  type="submit"
                  icon={
                    <div className="absolute right-3 bottom-0 top-0 flex items-center">
                      {state.loading && (
                        <LoadingIndicator className="text-white w-5 h-5" />
                      )}
                    </div>
                  }
                />
                <div className="checkbox-container">
                  <Link to={"/forgot-password"} className="forgot-password-link">
                    Forgot password?
                  </Link>
                </div>
              </form>
            </>
          }
        </div>
      </div>
    </HelmetProvider>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(null, mapDispatchToProps)(Login);

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Context } from "context/context";
import { Transition } from "@headlessui/react";
import SideBarContent from "./sidebar-content";

function SideBar({ routeName, user }) {
  const { mobileSidebar, setMobileSidebar } = React.useContext(Context);

  useEffect(() => {
    if (mobileSidebar) setMobileSidebar(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeName]);

  return (
    <>
      <Transition
        show={mobileSidebar}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
        className={
          "lg:hidden z-30 max-w-screen fixed left-0 right-0 top-0 bottom-0 sidebar h-screen overflow-y-auto overflow-x-hidden xl:static text-white bg-secondary custom-scroll"
        }
      >
        <SideBarContent user={user} routeName={routeName} />
      </Transition>
      <div className="hidden lg:block site-sidabar bg-secondary fixed left-0 top-0 right-0 bottom-0 sidebar h-screen overflow-y-auto overflow-x-hidden lg:static text-white custom-scroll">
        <SideBarContent user={user} routeName={routeName} />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

export default connect(mapStateToProps)(SideBar);

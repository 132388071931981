const UserIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 19C1 15.625 5.5 15.625 7.75 13.375C8.875 12.25 5.5 12.25 5.5 6.625C5.5 2.87538 6.99962 1 10 1C13.0004 1 14.5 2.87538 14.5 6.625C14.5 12.25 11.125 12.25 12.25 13.375C14.5 15.625 19 15.625 19 19"
        stroke="#6F52ED"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};

const GraphIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 16L8 11"
        stroke="#21B8C7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11C9.55228 11 10 10.5523 10 10C10 9.44772 9.55228 9 9 9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11Z"
        stroke="#21B8C7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 16C14.5523 16 15 15.5523 15 15C15 14.4477 14.5523 14 14 14C13.4477 14 13 14.4477 13 15C13 15.5523 13.4477 16 14 16Z"
        stroke="#21B8C7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 14L21 8M10 11L13 14L10 11Z"
        stroke="#21B8C7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const GraphLowIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 7L9 13L13 9L20.405 16.405"
        stroke="#FF4C61"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M20.405 16.405L21 17"
        stroke="#FF4C61"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21 14V17H18"
        stroke="#FF4C61"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};

const BookIcon = ({ className = "" }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 3V21H8C7.20435 21 6.44129 20.6839 5.87868 20.1213C5.31607 19.5587 5 18.7956 5 18V6C5 5.20435 5.31607 4.44129 5.87868 3.87868C6.44129 3.31607 7.20435 3 8 3H19Z"
        stroke="#4CB8FF"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M5 18C5 17.2044 5.31607 16.4413 5.87868 15.8787C6.44129 15.3161 7.20435 15 8 15H19"
        stroke="#4CB8FF"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};

const CupIcon = ({ className = "" }) => {
  return (
    <svg
      width="24"
      height="24"
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 21H17M6 2H18V11C18 14.3137 15.3137 17 12 17C8.68629 17 6 14.3137 6 11V2Z"
        stroke="#FFB800"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17V21"
        stroke="#FFB800"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 5V11H5C3.34315 11 2 9.65685 2 8C2 6.34315 3.34315 5 5 5H6ZM18 11V5H19C20.6569 5 22 6.34315 22 8C22 9.65685 20.6569 11 19 11H18Z"
        stroke="#FFB800"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ClockIcon = ({ className = "" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="#4CB8FF"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M12 5V12L16 16"
        stroke="#4CB8FF"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};

export { UserIcon, GraphIcon, GraphLowIcon, BookIcon, CupIcon, ClockIcon };

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import { Bell } from "assets/svg/sidebar/icons";
import profileImage from "assets/images/profile_photo.png";
import ProfileDefault from "assets/images/profile_default.png";
import mobileLogo from "assets/images/logo-white.png";
import { Context } from "context/context";
import { usePusherEvent } from "context/pusher";
import { useNotifications } from "redux/modules/notification/hooks";
import NotificationList from "./notification/notification-list";
import NotificationItem from "./notification/notification-item/notification-item";
import NotificationItemMore from "./notification/notification-item-more";
import NotificationItemDefault from "./notification/notification-item/notification-item-default";
import NotificationItemSystem from "./notification/notification-item/notification-item-system";

import LogoutIconSVG from "assets/svg/logout-icon";

export default function Header({ title, userData, setUserData }) {
  const { http, localstorage } = global.services;
  const { setMobileSidebar } = React.useContext(Context);
  const history = useHistory();
  const {
    notifications,
    hasUnreadNotification,
    addNotification,
    setNotifications,
    setNotificationUserReads,
    addNotificationUserRead,
  } = useNotifications();

  const [showNotification, setShowNotification] = useState(false);
  const [notificationMetadata, setNotificationMetadata] = useState({
    current_page: 0,
    last_page: 0,
  });
  console.log("================================")
  console.log(userData);
  console.log("================================")
  const canLoadMore = useMemo(
    () => notificationMetadata?.current_page < notificationMetadata?.last_page,
    [notificationMetadata]
  );

  usePusherEvent(
    `private-notifications-${userData.user.id}`,
    "push-notification-created-event",
    (data) => addNotification(data)
  );

  usePusherEvent(
    `private-notifications-${userData.user.id}`,
    "banner-notification-created-event",
    (data) => addNotification(data)
  );

  usePusherEvent(
    `private-notifications-${userData.user.id}`,
    "access-granted-event",
    (data) => addNotification(data)
  );

  const fetchNotifications = useCallback(
    async (page) => {
      try {
        const { data: response, status } = await http.get("notifications", {
          page,
          for: "header",
        });
        if (status === 200) {
          const nextNotifications =
            page === 1
              ? [...response.data]
              : [...notifications, ...response.data];
          setNotifications(nextNotifications);
          setNotificationMetadata(response.meta);
        }
      } catch (ex) {
        console.error(ex);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications]
  );

  const fetchUserNotifications = useCallback(async () => {
    try {
      const { data: response, status } = await http.get("user-notifications");
      if (status === 200) {
        setNotificationUserReads(response.data);
      }
    } catch (ex) {
      console.error(ex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNotificationOnRead = useCallback(
    async (id) => {
      try {
        const notification = notifications.find((notif) => notif.id === id);
        if (notification.isRead) return; // return immediately so we dont need to fire another request
        const { status } = await http.post("user-notifications", {
          notification_id: id,
        });
        if (status === 200) {
          addNotificationUserRead(id);
        }
      } catch (ex) {
        console.error(ex);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications]
  );

  const handleLogout = async () => {
    try {
      await http.post("auth/logout");
      setMobileSidebar(false); // Close sidebar
      localstorage.remove("user_data"); // Remove user data on storage
      setUserData(null); // Remove user data on redux
      history.push(`/login`); // Redirect to current page
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchNotifications();
    fetchUserNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header
      style={{ height: "79px" }}
      className="relative w-full bg-primary flex justify-between items-center site-header"
    >
      <div className="header-left flex items-center gap-4">
        <button
          onClick={() => setMobileSidebar(true)}
          className="lg:hidden text-white"
        >
          Menu
        </button>
        <img
          src={mobileLogo}
          alt={"Milestones Page Logo"}
          className={"w-28 lg:hidden"}
        />
        <h2 className="hidden 1sm:block text-2xl font-bold text-white">
          {title && title}
        </h2>
      </div>
      <div className="header-right flex items-center justify-end gap-4">
        {/* account */}
        <div className="flex items-center cursor-pointer">
          <div className="pr-4 hidden md:flex">
            <div className="profile-name">
              <span className="profile-name-text text-white text-right">
                {userData?.user.full_name || userData?.user.email.split("@")[0]}
              </span>
              <br />
              <p className="text-sm font-medium text-white text-right">
                {userData?.user.email}
              </p>
            </div>
          </div>
          <div className="profile-button">
            <img
              src={userData.profile?.profile_image_url || ProfileDefault}
              alt="two-column-layout-component-brand-logo"
              className="rounded-md h-full object-cover"
            />
          </div>
        </div>
        {/*  */}
        <div className="flex items-center divide-x divide-white">
          <Popup
            position="bottom right"
            open={showNotification}
            onClose={() => setShowNotification(false)}
            arrow={false}
            contentStyle={{ marginTop: 10, marginLeft: 5 }}
            trigger={
              <button
                className="pr-3"
                onClick={() => setShowNotification((open) => !open)}
              >
                <Bell show_ball={hasUnreadNotification} />
              </button>
            }
          >
            <NotificationList>
              {notifications.map((notification) => (
                <NotificationItem
                  key={`notification-${notification.id}`}
                  isRead={notification.isRead}
                  onRead={() => handleNotificationOnRead(notification.id)}
                >
                  {notification.category === "system" ? (
                    <NotificationItemSystem
                      onPreviewClick={() => setShowNotification(false)}
                      notification={notification}
                    />
                  ) : (
                    <NotificationItemDefault notification={notification} />
                  )}
                </NotificationItem>
              ))}
              {canLoadMore ? (
                <NotificationItemMore
                  onClick={() =>
                    fetchNotifications(notificationMetadata.current_page + 1)
                  }
                >
                  Load more
                </NotificationItemMore>
              ) : (
                <NotificationItemMore>You caught up</NotificationItemMore>
              )}
            </NotificationList>
          </Popup>
          {/*  */}
          <button className="pl-3.5" onClick={handleLogout}>
            <LogoutIconSVG />
          </button>
        </div>
      </div>
    </header>
  );
}
